<template>
    <div v-if="dataRetrieved">
        <div class="page-header-info-container">
            <div>
                <button type="button" class="btn btn-primary upload-button" @click="verifyInvoiceData">
                    Invoice Split Screen
                </button>

                <button type="button" class="btn btn-primary upload-button" @click="mergeAndOpenPDFs">
                    Open Invoices
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="openWaybill">Open Waybill</button>
                <button type="button" class="btn btn-primary upload-button" @click="displayCPCNPCAlert">
                    Set All CPC & NPC
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="displaySetAllCountriesAlert">
                    Set All Countries
                </button>
                <button
                    type="button"
                    class="btn btn-primary upload-button"
                    @click="sendRPARequest"
                    v-if="entryStatus === 'PARTIAL_STORAGE'"
                >
                    Trigger RPA
                </button>
            </div>
            <div>
                <div class="badge-container">
                    <h3>
                        <span
                            :class="[
                                'badge',
                                {
                                    'bg-success': classificationStatus === 'Classification Completed',
                                    'bg-warning text-dark': classificationStatus === 'Classification In Progress',
                                    'bg-danger': classificationStatus === 'Not Classified',
                                },
                                'fixed-badge',
                            ]"
                        >
                            {{ this.classificationStatus }}
                        </span>
                    </h3>
                </div>
            </div>
        </div>

        <VerifyDocumentData
            :invoices="commercialInvoices"
            :customsEntryForm="customsEntryForm"
            :createMode="false"
            v-if="displayDocumentVerificationView"
            :pdfFiles="invoiceDocumentSourceURLs"
        />

        <div v-if="!displayDocumentVerificationView">
            <h1>Entry Details</h1>
            <h2>Reference Number: {{ this.entryDetails?.entry_number }}</h2>

            <CustomsEntryForm
                ref="myCustomsForm"
                :customsEntryForm="customsEntryForm"
                @update-customs-entry-form="customsEntryForm = $event"
            />
            <!-- Commercial Invoices Table -->
            <div v-if="dataRetrieved">
                <DocumentTableView
                    :invoiceDataArray="commercialInvoices"
                    :customsEntryForm="customsEntryForm"
                    :documentVerificationFormat="false"
                    :linesToBeDeleted="linesToBeDeleted"
                    :invoicesToBeDeleted="invoicesToBeDeleted"
                    :linesToBeAdded="linesToBeAdded"
                    :invoicesToBeAdded="invoicesToBeAdded"
                    :invoiceFilesAdded="invoiceFilesAdded"
                />
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Number of Lines Read</th>
                                <th>Total Invoices Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ this.totalNumberOfLines }}</td>
                                <td>{{ this.totalFOBValue }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <table class="action-button-container">
                <tr>
                    <td>
                        <button
                            type="button"
                            class="btn btn-success"
                            :disabled="isGenerateCsvDisabled"
                            @click="generateAsycudaXMLFastAPI"
                        >
                            Generate Asycuda XML
                        </button>
                    </td>
                    <td>
                        <button type="button" class="btn btn-primary" @click="saveAllDetails">Save Entry</button>
                    </td>
                    <td>
                        <button type="button" class="btn btn-primary" @click="goToCustomsDeclarationPage(this.entryId)">
                            Generate Valuation Form
                        </button>
                    </td>
                    <td>
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="isGenerateCsvDisabled"
                            @click="generateWorksheetFastAPI"
                        >
                            Generate Worksheet
                        </button>
                    </td>
                    <td>
                        <button type="button" class="btn btn-primary" @click="generateDepositPDFFastAPI">
                            Generate Deposit Form
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button type="button" class="btn btn-primary" @click="generateCARICOMFastAPI">
                            Generate CARICOM
                        </button>
                    </td>
                    <td>
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="isGenerateCsvDisabled"
                            @click="approveClassification"
                            v-if="entryStatus === 'STORED_AND_NOT_CLASSIFIED'"
                        >
                            Approve Classification
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="isGenerateCsvDisabled"
                            @click="partialStorage"
                            v-if="entryStatus === 'CLASSIFICATION_OKAY'"
                        >
                            Partial Storage
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="isGenerateCsvDisabled"
                            @click="fullStorage"
                            v-if="entryStatus === 'PARTIAL_STORAGE'"
                        >
                            Full Storage
                        </button>
                    </td>
                    <td>
                        <!-- <button type="button" class="btn btn-primary" @click="displayRatesTable">
                            View Rates of Exchange
                        </button> -->
                        <button type="button" class="btn btn-primary" @click="generateBreakdownSheetFastAPI">
                            Generate Breakdown Sheet
                        </button>
                    </td>
                    <td>
                        <button type="button" class="btn btn-primary" @click="generateSpecialExemptionFastAPITT">
                            Generate Special Exemption
                        </button>
                    </td>
                    <td>
                        <button type="button" class="btn btn-primary" @click="generateCustomsOvertimeFastAPI">
                            Generate Overtime Form
                        </button>
                    </td>
                    <td v-if="typeOfDeclaration === 'Export'">
                        <button type="button" class="btn btn-primary" @click="generateScoonerBL()">
                            Generate Scooner BL
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import * as SweetAlertConnector from "../composables/SweetAlertConnector";
import * as Utilities from "@/composables/Utilities";
import CustomsEntryForm from "./CustomsEntry/CustomsEntryForm.vue";
import { CLASSIFICATION_STATUS, ENTRY_STATUS, FAST_API_RESPONSE_STATUS_CODES } from "../constants";

import moment from "moment-timezone";
import DocumentTableView from "./DocumentTableView.vue";
import VerifyDocumentData from "./VerifyDocumentData.vue";

import * as FastAPI from "@/composables/FastAPI";

export default {
    name: "EntryDetails",
    components: {
        CustomsEntryForm,
        DocumentTableView,
        VerifyDocumentData,
    },
    props: {
        entryId: {
            type: [String, Number],
            required: true,
        },
    },
    watch: {
        totalFOBValue(newVal) {
            this.customsEntryForm.totalCargoValue = newVal;
        },
        "customsEntryForm.waybillDate"(newDate) {
            console.log("Waybill Date Changed: ", newDate);
            this.updateRates(newDate);
        },
        "customsEntryForm.countryOfOrigin"(newCountry) {
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    if (!item.country_of_origin) {
                        item.country_of_origin = newCountry;
                    }
                });
            });
        },
        "customsEntryForm.regimeType"(newRegime) {
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                        (regime) => regime.regimeTypeId === newRegime
                    );
                    if (regimeData) {
                        item.cpcCode = regimeData.cpcCodes[0].cpcId;
                    }
                });
            });
        },
        commercialInvoices: {
            handler: function (invoices) {
                if (this.customsEntryForm.ratesOfExchange.length > 0) {
                    invoices.forEach((invoice) => {
                        // console.log("Invoice Currency: ", invoice.currency);
                        this.$watch(
                            () => invoice.currency,
                            (newVal) => {
                                if (newVal) {
                                    if (this.customsEntryForm.waybillDate) {
                                        this.updateRates(this.customsEntryForm.waybillDate);
                                    }
                                }
                            },
                            { deep: true, immediate: true }
                        );
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        typeOfDeclaration() {
            const typeCode =
                this.customsEntryForm.regimeType == undefined ? "" : this.customsEntryForm.regimeTypeCode?.slice(0, 2);
            return typeCode === "EX" ? "Export" : "Import";
        },
        totalFOBValue() {
            return this.commercialInvoices
                .reduce((acc, invoice) => acc + parseFloat(invoice.invoice_total), 0)
                .toFixed(2);
        },
        toalInsuranceValue() {
            return this.commercialInvoices.reduce((acc, invoice) => acc + parseFloat(invoice.insurance), 0).toFixed(2);
        },
        totalInlandValue() {
            return this.commercialInvoices.reduce((acc, invoice) => acc + parseFloat(invoice.inland), 0).toFixed(2);
        },
        totalOtherChargesValue() {
            return this.commercialInvoices
                .reduce((acc, invoice) => acc + parseFloat(invoice.otherCharges), 0)
                .toFixed(2);
        },
        netInvoicePrice() {
            return (
                parseFloat(this.totalFOBValue) +
                parseFloat(this.toalInsuranceValue) +
                parseFloat(this.totalInlandValue) +
                parseFloat(this.totalOtherChargesValue)
            );
        },
        formattedDate: {
            get() {
                const date = new Date(this.entryDetails.waybill_date);
                return date.toISOString().split("T")[0];
            },
            set(newValue) {
                this.entryDetails.waybill_date = newValue;
            },
        },
        isGenerateCsvDisabled() {
            return this.commercialInvoices.some((invoice) => {
                return invoice.lines.some((line) => !line.tariff_code || line.tariff_code.trim() === "");
            });
        },
        classificationStatus() {
            const classificationResult = Utilities.getClassificationStatus(this.commercialInvoices)
                ? CLASSIFICATION_STATUS.NOT_CLASSIFIED
                : CLASSIFICATION_STATUS.COMPLETED;
            if (classificationResult === false) return CLASSIFICATION_STATUS.NOT_CLASSIFIED;
            else {
                if (this.classificationApproved === 1) return CLASSIFICATION_STATUS.APPROVED;
                else return CLASSIFICATION_STATUS.NOT_CLASSIFIED;
            }
        },
        availableCPCCodes() {
            if (this.customsEntryForm.regimeTypeCode !== undefined && this.customsEntryForm.regimeTypeCode !== null) {
                const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                    (regime) => regime.regimeTypeCode === this.customsEntryForm.regimeTypeCode
                );
                if (regimeData) {
                    return regimeData.cpcCodes;
                } else return [];
            } else {
                return this.customsEntryForm.regimeTypeOptions.flatMap((regime) => regime.cpcCodes);
            }
        },
    },
    data() {
        return {
            invoiceDocumentSourceURLs: [],
            displayDocumentVerificationView: false,
            entryDetails: null,
            waybill: {},
            waybillSourceURL: "",
            waybillId: "",
            entry: {},
            entryDate: "",
            entryStatus: "",
            commercialInvoices: [],
            linesToBeDeleted: [],
            invoicesToBeDeleted: [],
            linesToBeAdded: [],
            invoiceFilesAdded: [],
            invoicesToBeAdded: [],
            dataRetrieved: false,
            loading: false,
            error: null,
            countries: [],
            totalNumberOfLines: 0,
            modeOfTransportOOptions: ["Air", "Ocean"],
            freightTypeOptions: ["FCL", "LCL", "Bulk", "Break Bulk"],
            packageTypeOptions: [],
            currencies: [],
            tradeAgreements: [],
            classificationApprovedBy: "",
            dateClassified: "",
            classificationAccuracyData: [],
            expandedIndex: null,
            classificationApproved: 0,
            customsEntryForm: {
                exporters: [],
                importers: [],
                vessels: [],
                ports: [],
                declarants: [],
                incotermsList: [],
                ratesOfExchange: [],
                importerId: "",
                exporterId: "",
                importerName: "",
                exporterName: "",
                importerTaxIdentificationNumber: "",
                exporterTaxIdentificationNumber: "",
                exporterCountry: "",
                declarantId: "",
                declarantTaxIdentificationNumber: "",
                modeOfTransport: "",
                freightType: "",
                waybillNumber: "",
                waybillDate: "",
                estimatedArrivalDate: "",
                vesselId: "",
                vesselName: "",
                incoTerms: "",
                portOfDischargeId: "",
                portOfDischargeName: "",
                portOfLoadingId: "",
                portOfLoadingName: "",
                netWeight: 0.0,
                grossWeight: 0.0,
                numberOfPackages: 0,
                packageType: "",
                packageTypes: [],
                marksAndNumbers: "As Addressed",
                freightCurrency: null,
                insuranceCurrency: null,
                otherChargesCurrency: null,
                totalCargoValue: 0.0,
                freightAmount: 0.0,
                insuranceAmount: 0.0,
                otherCharges: 0.0,
                insuranceRateOfExchange: 0.0,
                otherChargesRateOfExchange: 0.0,
                freightRateOfExchange: 0.0,
                freightExchangeRateId: null,
                insuranceExchangeRateId: null,
                otherChargesExchangeRateId: null,
                rateOfExchange: 0.0,
                regimeType: "",
                regimeTypeCode: "",
                regimeTypeOptions: [],
                depositAmount: 0.0,
                containerCharges: 0.0,
                additionalCharges: 0.0,
                localFee: 0.0,
                countryOfOrigin: null,
                countryOfFinalDestination: null,
                countryLastProvinance: null,
                tradingCountry: null,
                userDetails: null,
                locationOfGoodsId: null,
                manifestReference: "",
                deliveryPlace: "",
            },
            manualExemptionApplied: false,
        };
    },
    mounted() {
        // this.fetchAllDetails();
        this.getAllDetails();
        this.getRegimeTypes();
        this.fetchCountries();
        this.fetchTradeAgreements();
    },
    methods: {
        verifyInvoiceData,
        displaySetAllCountriesAlert(){
            SweetAlertConnector.showCountrySelectionAlert(this.customsEntryForm.countryList).then((result) => {
                console.log("Selected Country", result);
                if (result === undefined) return;
                this.commercialInvoices.forEach((invoice) => {
                    invoice.lines.forEach((line) => {
                        line.country_of_origin_id = parseInt(result);
                    });
                });
                SweetAlertConnector.showSuccessAlert("Updated All Country of Origin Lines");
            });
        },
        displayCPCNPCAlert() {
            SweetAlertConnector.showCPCNPCAlert(this.availableCPCCodes).then((result) => {
                console.log("Selectd Codes", result);
                if (result === undefined) return;
                this.commercialInvoices = Utilities.setCPCANCAllLines(
                    this.commercialInvoices,
                    parseInt(result.cpcCode),
                    parseInt(result.npcCode)
                );
                SweetAlertConnector.showSuccessAlert("Updated All CPC & NPC Lines");
            });
        },
        async updateWaybillDetails() {
            console.log('--------------------------')
            console.log(this.customsEntryForm)
            const waybillObject = {
                waybill_number: this.customsEntryForm.waybillNumber,
                waybill_date: this.customsEntryForm.waybillDate,
                shipped_on_board_date: this.customsEntryForm.waybillDate,
                freight_type_id: this.customsEntryForm.freightType,
                mode_of_transport: this.customsEntryForm.modeOfTransport,
                voyage_flight_number: "",
                description_of_goods: "",
                country_of_origin_id: this.customsEntryForm.countryOfOrigin,
                package_type_id: this.customsEntryForm.packageType,
                package_quantity: this.customsEntryForm.numberOfPackages,
                vessel_id: this.customsEntryForm.vesselId,
                gross_weight: this.customsEntryForm.grossWeight,
                importer_id: this.customsEntryForm.importerId,
                exporter_id: this.customsEntryForm.exporterId,
                office_of_destination_id: this.customsEntryForm.portOfDischargeId,
                office_of_entry_exit_id: this.customsEntryForm.portOfLoadingId,
                location_of_goods_id: this.customsEntryForm.locationOfGoodsId,
                international_freight: {
                    amount: this.customsEntryForm.freightAmount,
                    currency_id: this.customsEntryForm.freightCurrency,
                    rate_of_exchange_id: this.customsEntryForm.freightExchangeRateId,
                },
                insurance: {
                    amount: this.customsEntryForm.insuranceAmount,
                    currency_id: this.customsEntryForm.insuranceCurrency,
                    rate_of_exchange_id: this.customsEntryForm.insuranceExchangeRateId,
                },
                other_charges: {
                    amount: this.customsEntryForm.otherCharges,
                    currency_id: this.customsEntryForm.otherChargesCurrency,
                    rate_of_exchange_id: this.customsEntryForm.otherChargesExchangeRateId,
                },
            };
            console.log("Waybill Object: ", waybillObject);
            try {
                // Call the function to update the waybill
                const response = await FastAPI.updateWaybill(this.waybillId, waybillObject);

                if (response.status === FAST_API_RESPONSE_STATUS_CODES.SUCCESS) {
                    console.log("Waybill updated successfully:", response.data);
                    // SweetAlertConnector.showSuccessAlert("Waybill details updated successfully.");
                    return response.data;
                }

                if (response.status === FAST_API_RESPONSE_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                    const errorMessage = Utilities.generateErrorMessage(response.data);
                    SweetAlertConnector.showErrorAlert(errorMessage);
                    throw new Error(errorMessage);
                } else {
                    throw new Error("Failed to update waybill details.");
                }
            } catch (error) {
                console.error("Failed to update waybill details:", error);
                SweetAlertConnector.showErrorAlert(
                    "An error occurred while updating waybill details. Please try again."
                );
            }
        },
        async saveInvoiceDataFastAPI() {
            console.log("Checking invoices to be added: ", this.invoicesToBeAdded.length);
            if (this.invoicesToBeAdded.length === 0) {
                return;
            }
            const currentInvoiceLength = this.commercialInvoices.length;
            let invoiceFileUrls = [];
            try {
                const invoiceFiles = this.invoiceFilesAdded.map((file, index) => {
                    const currentIndex = currentInvoiceLength + index + 10;
                    const fileName = `${this.entryDetails?.entry_number}-${
                        this.entryDetails?.id
                    }-${currentIndex}-${file.name.split(".")[0]}-CommercialInvoice.${file.name.split(".").pop()}`;
                    console.log("File Name: ", fileName);
                    const renamedFile = new File([file], fileName, { type: file.type });
                    return renamedFile;
                });
                console.log("Invoice Files: ", invoiceFiles);

                invoiceFileUrls = await FastAPI.uploadFiles(invoiceFiles);
                console.log("Invoice File URLs: ", invoiceFileUrls);
            } catch (error) {
                console.error("Failed to upload invoice files:", error);
                SweetAlertConnector.showErrorAlert("Failed to upload invoice files");
                throw error;
            }
            const invoiceData = Utilities.prepareInvoiceDataForFastAPI(
                this.invoicesToBeAdded,
                this.availableCPCCodes,
                this.entryDetails?.id,
                this.customsEntryForm.packageType,
                this.customsEntryForm.numberOfPackages,
                invoiceFileUrls
            );
            console.log("Invoice Data to be added: ", invoiceData);
            return FastAPI.createCommercialInvoices(invoiceData)
                .then((response) => {
                    console.log("Invoice Response: ", response.data);
                    if (response.status === FAST_API_RESPONSE_STATUS_CODES.SUCCESS) {
                        return response.data;
                    }
                    if (response.status === FAST_API_RESPONSE_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                        const errorMessage = Utilities.generateErrorMessage(response.data);
                        throw new Error(errorMessage);
                    } else {
                        throw new Error("Failed to save invoices");
                    }
                })
                .catch((error) => {
                    console.error("Failed to save invoices:", error);
                    SweetAlertConnector.showErrorAlert(error);
                    throw error;
                });
        },
        async updateCommercialInvoiceDetails() {
            const invoiceData = this.commercialInvoices
                .filter((invoice) => invoice.id)
                .map((invoice) => {
                    return {
                        id: invoice.id,
                        invoice_number: invoice.invoice_number,
                        invoice_date: invoice.invoice_date,
                        invoice_total: invoice.invoice_total,
                        inland: invoice.inland,
                        insurance: invoice.insurance,
                        other_charges: invoice.otherCharges || 0,
                        rate_of_exchange_id: invoice.rateOfExchangeId,
                        supplier_id: invoice.supplier_id,
                        currency_id: invoice.currency,
                        customs_entry_id: this.entryDetails?.id,
                        purchase_order_number: "", // TODO
                        invoice_lines: invoice.lines.map((line) => {
                            const cpcNpcRelationId = Utilities.findCPCNPCReltation(
                                line.cpcCode,
                                line.npcCode,
                                this.availableCPCCodes
                            );
                            return {
                                product_code: line.product_code || "",
                                id: line.id,
                                quantity: line.quantity,
                                unit_price: line.unit_price,
                                description: line.description,
                                extension_price: line.extension_price,
                                discount_price: 0, // TODO
                                trade_agreement_id: line.trade_agreement_id,
                                commodity_code_id: line.commodityCodeId,
                                country_of_origin_id:
                                    line.country_of_origin_id || this.customsEntryForm.countryOfOrigin || "",
                                cpc_npc_code_id: cpcNpcRelationId,
                                package_type_id: this.customsEntryForm.packageType,
                                package_quantity: this.customsEntryForm.numberOfPackages,
                                marks_and_numbers: this.customsEntryForm.marksAndNumbers,
                            };
                        }),
                    };
                });
            console.log("Invoice Data: ", invoiceData);
            try {
                // Call the function to update commercial invoices
                const response = await FastAPI.updateCommercialInvoices(invoiceData);

                if (response.status === FAST_API_RESPONSE_STATUS_CODES.SUCCESS) {
                    console.log("Commercial Invoices Updated Successfully:", response.data);
                    // SweetAlertConnector.showSuccessAlert("Commercial invoices updated successfully.");
                    return response.data;
                }

                if (response.status === FAST_API_RESPONSE_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                    const errorMessage = Utilities.generateErrorMessage(response.data);
                    SweetAlertConnector.showErrorAlert(errorMessage);
                    throw new Error(errorMessage);
                } else {
                    throw new Error("Failed to update commercial invoices.");
                }
            } catch (error) {
                console.error("Failed to update commercial invoices:", error);
                SweetAlertConnector.showErrorAlert(
                    "An error occurred while updating commercial invoices. Please try again."
                );
                throw error;
            }
        },
        async updateEntryDetails() {
            const entryObject = {
                entry_date: this.entryDate,
                entry_number: this.entryDetails?.entry_number,
                declarant_id: this.customsEntryForm.declarantId,
                regime_type_id: this.customsEntryForm.regimeType,
                waybill_id: this.waybillId,
                local_charge: this.customsEntryForm.localFee,
                deposit_fee: this.customsEntryForm.depositAmount,
                container_charges: this.customsEntryForm.containerCharges,
                country_of_first_destination_id: this.customsEntryForm.countryLastProvinance,
                trading_country_id: this.customsEntryForm.tradingCountry,
                country_of_export_id: this.customsEntryForm.countryOfOrigin,
                country_of_destination_id: this.customsEntryForm.countryOfFinalDestination,
                delivery_terms_id: this.customsEntryForm.incoTerms,
                delivery_place: this.customsEntryForm.deliveryPlace,
                manifest_reference: this.customsEntryForm.manifestReference,
                status: this.entryStatus,
            };
            console.log("Entry Object: ", entryObject);
            try {
                // Call the function to update the customs entry
                const response = await FastAPI.updateCustomsEntry(this.entryDetails?.id, entryObject);

                if (response.status === FAST_API_RESPONSE_STATUS_CODES.SUCCESS) {
                    console.log("Entry updated successfully:", response.data);
                    // SweetAlertConnector.showSuccessAlert("Entry details updated successfully.");
                    return response.data;
                }

                if (response.status === FAST_API_RESPONSE_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                    const errorMessage = Utilities.generateErrorMessage(response.data);
                    SweetAlertConnector.showErrorAlert(errorMessage);
                    throw new Error(errorMessage);
                } else {
                    throw new Error("Failed to update entry details.");
                }
            } catch (error) {
                console.error("Failed to update entry details:", error);
                SweetAlertConnector.showErrorAlert("An error occurred while updating entry details. Please try again.");
                throw error;
            }
        },
        async saveAllDetails() {
            try {
                SweetAlertConnector.showLoadingAlert("Saving All Details...");
                let promises = [
                    this.updateEntryDetails(),
                    this.updateWaybillDetails(),
                    this.updateCommercialInvoiceDetails(),
                    this.saveInvoiceDataFastAPI(),
                ];
                return Promise.all(promises)
                    .then(() => {
                        console.log("All Details Saved Successfully");
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("All Details Saved Successfully!");
                    })
                    .catch((error) => {
                        console.error("Error saving all details", error);
                        SweetAlertConnector.showErrorAlert("Failed to save all details");
                    });
            } catch (error) {
                console.error("Error saving entry details", error);
            }
        },
        async generateAsycudaXMLFastAPI() {
            console.log("Generating Asycuda XML...HHH");
            if(!this.checkMandatoryData()) return;
            SweetAlertConnector.asycudaXmlAlert().then(async (response) => {
                console.log(response);
                if (response === undefined) return;
                await this.saveAllDetails();
                SweetAlertConnector.showLoadingAlert("Generating Asycuda XML...");
                console.log("Generating Asycuda XML for entry Id", this.entryId);
                FastAPI.generateXML(this.entryId, response.use_local_currency, response.merge_items)
                    .then((response) => {
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("Asycuda XML Generated Successfully!");
                        const fileURL = window.URL.createObjectURL(new Blob([response], { type: "application/xml" }));
                        const fileLink = document.createElement("a");
                        const fileName = `Asycuda XML - ${this.entryDetails.entry_number}.xml`;
                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", fileName);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        fileLink.remove();
                    })
                    .catch((error) => {
                        console.error("Failed to generate Asycuda XML:", error);
                        SweetAlertConnector.showErrorAlert("Failed to generate Asycuda XML!");
                    });
            });
        },
        async getClassificationAccuracy() {
            try {
                const classificationAccuracyData = await FastAPI.getClassificationStatisticsByCustomsEntry(
                    this.entryId
                );
                return classificationAccuracyData;
            } catch (error) {
                console.error("Error fetching classification statistics", error);
            }
        },
        async getAllDetails() {
            try {
                const entryDetails = await FastAPI.getCustomsEntry(this.entryId);
                this.classificationAccuracyData = await this.getClassificationAccuracy();
                await this.getCurrencies();

                // console.log("Classification Data: ", this.classificationAccuracyData);
                this.dataRetrieved = true;
                this.entryDetails = entryDetails;
                this.entryDate = entryDetails.entry_date;
                this.entryStatus = entryDetails.status;
                const waybillData = entryDetails.waybill;
                console.log("Fast API Waybill Data: ", waybillData);
                this.waybillId = waybillData.id;
                const invoiceData = entryDetails.commercial_invoices;
                console.log("Fast API Invoice Data: ", invoiceData);
                console.log("Entry Details: ", entryDetails);
                //TODO : Waybill Date.

                this.customsEntryForm.importerId = waybillData.importer_id;
                this.customsEntryForm.exporterId = waybillData.exporter_id;
                this.customsEntryForm.waybillNumber = waybillData.waybill_number;
                this.customsEntryForm.modeOfTransport = waybillData.freight_type
                    ? waybillData.freight_type.mode_of_transport_id
                    : "";
                this.customsEntryForm.freightType = waybillData.freight_type_id;
                this.customsEntryForm.vesselId = waybillData.vessel_id;
                this.customsEntryForm.waybillDate = waybillData.waybill_date;
                this.customsEntryForm.portOfDischargeId = waybillData.office_of_destination_id;
                this.customsEntryForm.portOfLoadingId = waybillData.office_of_entry_exit_id;
                this.customsEntryForm.grossWeight = waybillData.gross_weight;
                this.customsEntryForm.numberOfPackages = waybillData.package_quantity;
                this.customsEntryForm.packageType = waybillData.package_type_id;
                this.customsEntryForm.marksAndNumbers = "As Addressed";
                // this.customsEntryForm.freightCurrency = waybillData.freight_currency;
                this.waybillSourceURL = waybillData.file_url;
                

                this.customsEntryForm.incoTerms = entryDetails.delivery_terms_id;
                this.customsEntryForm.deliveryPlace = entryDetails.delivery_place;
                this.customsEntryForm.manifestReference = entryDetails.manifest_reference;
                this.customsEntryForm.locationOfGoodsId = waybillData.location_of_goods_id;

                this.customsEntryForm.localFee = entryDetails.local_charge;
                this.customsEntryForm.depositAmount = entryDetails.deposit_fee;
                this.customsEntryForm.containerCharges = entryDetails.container_charges;
                
                if (waybillData.international_freight !== undefined && waybillData.international_freight !== null) {
                    this.customsEntryForm.freightAmount = waybillData.international_freight.amount;
                    if (waybillData.international_freight.rate_of_exchange !== undefined && waybillData.international_freight.rate_of_exchange !== null) {
                        this.customsEntryForm.freightCurrency =
                            waybillData.international_freight.rate_of_exchange?.base_currency_id || null;
                        this.customsEntryForm.freightRateOfExchange =
                            waybillData.international_freight.rate_of_exchange.exchange_rate;
                        this.customsEntryForm.freightExchangeRateId = waybillData.international_freight.rate_of_exchange.id;
                    }
                    else {
                        console.log("International Freight Rate of Exchange Not Found", this.currencies);
                        this.customsEntryForm.freightCurrency = this.currencies.find(
                            (currency) => currency.code === "USD"
                        )?.id;
                        this.customsEntryForm.freightRateOfExchange = 0.0;
                    }
                } else {
                    console.log("International Freight Not Found");
                    this.customsEntryForm.freightAmount = 0.0;
                    this.customsEntryForm.freightCurrency = this.currencies.find(
                        (currency) => currency.code === "USD"
                    )?.id;
                    this.customsEntryForm.freightRateOfExchange = 0.0;
                }

                if (waybillData.insurance !== undefined && waybillData.insurance !== null) {
                    this.customsEntryForm.insuranceAmount = waybillData.insurance.amount;
                    if(waybillData.insurance.rate_of_exchange !== undefined && waybillData.insurance.rate_of_exchange !== null) {
                        this.customsEntryForm.insuranceCurrency = waybillData.insurance.rate_of_exchange?.base_currency_id || null;
                        this.customsEntryForm.insuranceRateOfExchange =
                            waybillData.insurance.rate_of_exchange.exchange_rate;
                        this.customsEntryForm.insuranceExchangeRateId = waybillData.insurance.rate_of_exchange.id;
                    }
                    else {
                        this.customsEntryForm.insuranceCurrency = this.currencies.find(
                            (currency) => currency.code === "USD"
                        )?.id;
                        this.customsEntryForm.insuranceRateOfExchange = 0.0;
                    }
                } else {
                    this.customsEntryForm.insuranceAmount = 0.0;
                    this.customsEntryForm.insuranceCurrency = this.currencies.find(
                        (currency) => currency.code === "USD"
                    )?.id;
                    this.customsEntryForm.insuranceRateOfExchange = 0.0;
                }

                if (waybillData.other_charges !== undefined && waybillData.other_charges !== null) {
                    this.customsEntryForm.otherCharges = waybillData.other_charges.amount;
                    if(waybillData.other_charges.rate_of_exchange !== undefined && waybillData.other_charges.rate_of_exchange !== null) {
                        this.customsEntryForm.otherChargesCurrency = waybillData.other_charges.rate_of_exchange.base_currency_id;
                        this.customsEntryForm.otherChargesRateOfExchange =
                            waybillData.other_charges.rate_of_exchange.exchange_rate;
                        this.customsEntryForm.otherChargesExchangeRateId = waybillData.other_charges.rate_of_exchange.id;
                    }
                    else {
                        this.customsEntryForm.otherChargesCurrency = this.currencies.find(
                            (currency) => currency.code === "USD"
                        )?.id;
                        this.customsEntryForm.otherChargesRateOfExchange = 0.0;
                    }
                } else {
                    this.customsEntryForm.otherCharges = 0.0;
                    this.customsEntryForm.otherChargesCurrency = this.currencies.find(
                        (currency) => currency.code === "USD"
                    )?.id;
                    this.customsEntryForm.otherChargesRateOfExchange = 0.0;
                }

                this.customsEntryForm.countryOfOrigin = entryDetails.country_of_export_id;
                this.customsEntryForm.countryOfFinalDestination = entryDetails.country_of_destination_id;
                this.customsEntryForm.countryLastProvinance = entryDetails.country_of_destination_id;

                this.customsEntryForm.regimeType = entryDetails.regime_type_id;
                this.customsEntryForm.declarantId = entryDetails.declarant_id;

                

                this.commercialInvoices = invoiceData.map((invoice) => {
                    const { invoice_lines, ...rest } = invoice; // Destructure to extract "invoice_lines" and keep the rest
                    const currencyId = invoice.rate_of_exchange
                        ? this.currencies.find((currency) => currency.id === invoice.rate_of_exchange.base_currency_id)
                              ?.id
                        : "";
                    const rateOfExchangeDetails = invoice.rate_of_exchange;
                    const rateOfExchange = rateOfExchangeDetails ? rateOfExchangeDetails.exchange_rate : "";

                    const modifiedLines = invoice_lines.map((line) => {
                        const { ...lineRest } = line;
                        const commodityCode = line.commodity_code ? `${line.commodity_code.code}` : "";
                        const cpcCodeId = line.valid_cpc_npc?.cpc_code_id || "";
                        const npcCodeId = line.valid_cpc_npc?.npc_code_id || "";
                        return {
                            tariff_code: Utilities.insertFullStops(commodityCode),
                            cpcCode: cpcCodeId,
                            npcCode: npcCodeId,
                            ...lineRest,
                        };
                    });
                    return {
                        ...rest, // Spread the rest of the properties
                        lines: modifiedLines, // Rename "invoice_lines" to "lines"
                        currency: currencyId,
                        rate_of_exchangeDetails: rateOfExchangeDetails,
                        rate_of_exchange: rateOfExchange,
                    };
                });
                this.inializeTaxes();
                this.getTotalNumberOfLines();
                console.log("Commercial Invoices: ", this.commercialInvoices);
                const selectedUserDetails = entryDetails.user;
                if(this.customsEntryForm.waybillDate !== undefined && this.customsEntryForm.waybillDate != null) {
                    this.updateRates(this.customsEntryForm.waybillDate, selectedUserDetails);
                }
                this.retrieveInvoiceDocumentSourceURL();
            } catch (error) {
                SweetAlertConnector.showErrorAlert("Failed to fetch entry details");
                console.error("Error fetching entry details", error);
            }
        },
        async getCurrencies() {
            try {
                this.currencies = await FastAPI.getCurrencies();
            } catch (error) {
                console.error("Error fetching currencies", error);
            }
        },
        async getPackageTypes() {
            this.packageTypeOptions = await FastAPI.getPackageTypes();
        },
        async generateBreakdownSheetFastAPI() {
            console.log("Generating Breakdown Sheet Fast API");
            SweetAlertConnector.showLoadingAlert("Generating Breakdown Sheet...");
            try {
                await FastAPI.generateBreakdownSheet(this.entryId, this.entryDetails?.entry_number);
                SweetAlertConnector.closeCurrentAlert();
            } catch (error) {
                console.error("Failed to generate Breakdown Sheet:", error);
                SweetAlertConnector.showErrorAlert("Failed to generate Breakdown Sheet!");
            }
        },
        checkMandatoryData() {
            console.log("Checking Mandatory Data");
            if (this.customsEntryForm.importerId === null || this.customsEntryForm.exporterId === null) {
                SweetAlertConnector.showErrorAlert("Please fill in all mandatory fields before proceeding!");
                return false;
            }
            if (this.checkAllCPCAndNPCId() === false) {
                SweetAlertConnector.showErrorAlert("Please ensure all CPC Codes are filled in before proceeding!");
                return false;
            }
            const typeOfDeclaration =
                this.customsEntryForm.regimeType == undefined ? "" : this.customsEntryForm.regimeTypeCode?.slice(0, 2);
            console.log("Type of Declaration: ", typeOfDeclaration);
            return true;
        },
        checkAllCPCAndNPCId(){
            let allCodesExist = true;
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    if (line.cpc_npc_code_id === null) {
                        allCodesExist = false;
                    }
                });
            });
            return allCodesExist;
        },
        checkAllCPCCodes() {
            let allCodesExist = true;
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    console.log("Line: ", line);
                    if (
                        line.cpcCode === "" ||
                        line.cpcCode === "N/A" ||
                        line.cpcCode === null ||
                        line.cpcCode === undefined
                    ) {
                        allCodesExist = false;
                    }
                });
            });
            return allCodesExist;
        },
        checkAllNPCCodes() {
            let allCodesExist = true;
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    if (
                        line.npcCode === "" ||
                        line.npcCode === "N/A" ||
                        line.npcCode === null ||
                        line.npcCode === undefined ||
                        line.npcCode.trim() === ""
                    ) {
                        allCodesExist = false;
                    }
                });
            });
            return allCodesExist;
        },
        setToPartialStorage() {
            this.entryStatus = "PARTIAL_STORAGE";
            this.saveData();
        },
        setToFullStorage() {
            this.entryStatus = "FULL_STORAGE";
            this.saveData();
        },
        mergeAndOpenPDFs,
        openWaybill,
        fetchTradeAgreements() {
            FastAPI.getTradeAgreements().then((data) => {
                this.tradeAgreements = data;
            });
        },
        displayRatesTable() {
            if (
                this.customsEntryForm.waybillDate === undefined ||
                this.customsEntryForm.waybillDate === null ||
                this.customsEntryForm.waybillDate.trim().length === 0
            ) {
                SweetAlertConnector.showErrorAlert("Please Enter a valid Waybill Date to get rates of Exchange!");
                return;
            }
            if (this.customsEntryForm.ratesOfExchange.length === 0) this.updateRates(this.customsEntryForm.waybillDate);
            const currencies = [
                this.customsEntryForm.freightCurrency,
                this.customsEntryForm.insuranceCurrency,
                this.customsEntryForm.otherChargesCurrency,
            ];
            const ratesToDisplay = this.customsEntryForm.ratesOfExchange.filter((rate) =>
                currencies.includes(rate.base_currency)
            );
            SweetAlertConnector.displayRateOfExchangeTable(ratesToDisplay);
        },
        updateRates(shippedOnBoardDate, entryUserDetails = null) {
            if ((this.customsEntryForm.userDetails === null || this.customsEntryForm.userDetails === undefined) && (entryUserDetails === null || entryUserDetails === undefined)) {
                return;
            }
            const selectedUserDetails = entryUserDetails || this.customsEntryForm.userDetails;
            const invoiceCurrency = this.commercialInvoices.filter((invoice) => invoice.currency).map((invoice) => invoice.currency);
            const chargeCurrencies = [
                this.customsEntryForm.freightCurrency,
                this.customsEntryForm.insuranceCurrency,
                this.customsEntryForm.otherChargesCurrency,
            ];
            const allCurrencies = [...invoiceCurrency, ...chargeCurrencies];
            const uniqueCurrencies = [...new Set(allCurrencies)];

            Utilities.getRatesOfExchange(
                uniqueCurrencies,
                shippedOnBoardDate,
                selectedUserDetails.mawi_business.country.main_currency_id
            ).then((rates) => {
                this.customsEntryForm.ratesOfExchange = rates;
                this.commercialInvoices.forEach((invoice) => {
                    if (invoice.currency) {
                        const invoiceCurrency = rates.find((rate) => rate.base_currency_id === invoice.currency);
                        invoice.rate_of_exchange = invoiceCurrency ? invoiceCurrency.exchange_rate : 1.0;
                        invoice.rateOfExchangeId = invoiceCurrency ? invoiceCurrency.id : 0;
                    }
                });
            });
        },
        verifyClassificationCodes() {
            let hasInvalidCode = false;

            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    console.log("Line: ", line.commodityCodeStatus);
                    if (line.commodityCodeStatus === "invalid") {
                        SweetAlertConnector.showErrorAlert(
                            `The following commodity code does not exist on Asycuda - ${line.tariff_code}. Please update this and try again.`
                        );
                        hasInvalidCode = true;
                    }
                });
            });

            return !hasInvalidCode;
        },
        partialStorage() {
            this.entryStatus = ENTRY_STATUS.PARTIAL_STORAGE;
            this.saveData();
        },
        fullStorage() {
            this.entryStatus = ENTRY_STATUS.FULL_STORAGE;
            this.saveData();
        },
        async approveClassification() {
            if (!this.verifyClassificationCodes()) {
                return;
            }
            console.log("Approving Classification");
            this.entryStatus = ENTRY_STATUS.CLASSIFICATION_OKAY;
            await this.saveAllDetails();
            console.log("All Details Saved");
            this.classificationAccuracyData.forEach((accuracyData) => {
                this.commercialInvoices.forEach((invoice) => {
                    const matchingLine = invoice.lines.find(
                        (line) => line.id === accuracyData.commercial_invoice_line_id
                    );
                    if (matchingLine !== undefined && matchingLine !== null) {
                        accuracyData.approved_code_id = matchingLine.commodityCodeId;
                    }
                });
            });
            SweetAlertConnector.showLoadingAlert("Approving Classification...");
            try {
                const classificationStatisticsData = await FastAPI.updateClassificationStatisticsByInvoiceLines(
                    this.classificationAccuracyData
                );
                console.log("Classification Statistics Response Data: ", classificationStatisticsData);
                const userDetails = await FastAPI.getUserDetails();
                const declarationStatisticObject = {
                    customs_entry_id: this.entryId,
                    action_performed: ENTRY_STATUS.CLASSIFICATION_OKAY,
                    performed_by_id: userDetails.id,
                    performed_on: new Date().toISOString(),
                };
                await FastAPI.postDeclarationStatistics(declarationStatisticObject);
                console.log("Posted Declaration Statistics");
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert("Classification Approved Successfully!");
            } catch (error) {
                console.error("Failed to update classification accuracy:", error);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showErrorAlert(
                    "An error occurred while updating classification accuracy. Please try again."
                );
            }
        },
        inializeTaxes() {
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    if (
                        line.tariff_code !== undefined &&
                        line.tariff_code !== null &&
                        line.tariff_code.trim().length > 0 &&
                        line !== "N/A"
                    ) {
                        this.updateTariffCode(line, "tariff_code", line.tariff_code);
                    }
                });
            });
        },

        updateTariffCode(data, field, value) {
            this.updateInvoiceData(data, field, value);
            if (value === undefined || value === null || value.trim().length === 0 || value === "N/A") {
                data.icdCharge = 0;
                data.vatCharge = 0;
                return;
            }
            FastAPI.getCommodityCodeCharges(value.replace(/\./g, ""))
                .then((chargesResponse) => {
                    // const commodityCodeId = chargesResponse.id;
                    // let icdObject = chargesResponse.charges["import custom duty"];
                    // const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject[0].charge_amount);
                    // let vatObject = chargesResponse.charges["value added tax"];
                    // const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject[0].charge_amount);
                    // data.icdCharge = icdCharge;
                    // data.vatCharge = vatCharge;
                    // data.commodityCodeStatus = "valid";
                    // data.applicableTaxes = chargesResponse.charges;
                    // data.commodity_description = chargesResponse.description;
                    // data.commodityCodeId = commodityCodeId;

                    const commodityCodeId = chargesResponse.id;
                    let icdObject = Utilities.findChargeObject(chargesResponse, "import custom duty");
                    const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject.charge_amount);

                    const vatObject = Utilities.findChargeObject(chargesResponse, "value added tax");
                    const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject.charge_amount);

                    data.commodityCodeId = commodityCodeId;
                    data.icdCharge = icdCharge;
                    data.vatCharge = vatCharge;
                    data.commodityCodeStatus = "valid";
                    data.commodity_description = chargesResponse.description;
                })
                .catch((error) => {
                    console.error("Failed to get commodity charges:", error);
                    data.commodityCodeStatus = "invalid";
                    data.icdCharge = 0;
                    data.vatCharge = 0;
                });
        },
        async generateCARICOMFastAPI() {
            console.log("Generating CARICOM Fast API");
            SweetAlertConnector.caricomAlert().then((response) => {
                FastAPI.generateCaricomPdf(this.entryId, response, this.entryDetails?.entry_number)
                    .then(() => {
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("CARICOM Generated Successfully!");
                    })
                    .catch((error) => {
                        console.error("Failed to generate CARICOM:", error);
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showErrorAlert("Failed to generate CARICOM!");
                    });
            });
        },
        buildWorksheetObject() {
            const worksheetObject = Utilities.formatWorkSheetData(
                this.entryDetails?.entry_number,
                "TT",
                "Ramnarine Rampersad",
                "BR0285",
                this.customsEntryForm.regimeTypeCode,
                this.customsEntryForm.importerName,
                this.customsEntryForm.importerTaxIdentificationNumber,
                this.customsEntryForm.exporterName,
                this.customsEntryForm.exporterTaxIdentificationNumber,
                this.customsEntryForm.vesselName,
                this.customsEntryForm.portOfDischargeName,
                this.customsEntryForm.depositAmount,
                this.customsEntryForm.containerCharges,
                this.customsEntryForm.additionalCharges,
                this.customsEntryForm.localFee,
                this.customsEntryForm.freightAmount,
                this.customsEntryForm.insuranceAmount,
                this.customsEntryForm.otherCharges
            );
            const invoiceSummary = Utilities.formatCommercialData(
                this.commercialInvoices,
                this.customsEntryForm.freightAmount,
                this.totalFOBValue
            );
            worksheetObject.invoices = invoiceSummary;
            return worksheetObject;
        },
        async generateScoonerBL(){
            console.log("Generating Scooner BL Fast API");
            await this.saveAllDetails();
            SweetAlertConnector.showLoadingAlert("Generating Scooner BL...");
            FastAPI.generateScoonerBLPDF(this.entryId, this.entryDetails?.entry_number)
                .then(() => {
                    SweetAlertConnector.closeCurrentAlert();
                    SweetAlertConnector.showSuccessAlert("Scooner BL Generated Successfully!");
                })
                .catch((error) => {
                    console.error("Failed to generate Scooner BL:", error);
                    SweetAlertConnector.closeCurrentAlert();
                    SweetAlertConnector.showErrorAlert("Failed to generate Scooner BL!");
                });
        },
        async generateWorksheetFastAPI() {
            SweetAlertConnector.worksheetFormatAlert().then(async (merge_invoices) => {
                console.log("Worksheet Format Response: ", merge_invoices);
                if (merge_invoices === undefined) return;
                await this.saveAllDetails();
                SweetAlertConnector.showLoadingAlert("Generating Worksheet...");
                console.log("Generating Worksheet for entry Id", this.entryId, this.entryDetails?.entry_number);
                FastAPI.generateWorksheetPDF(this.entryId, this.entryDetails?.entry_number, merge_invoices)
                    .then(() => {
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("Worksheet Generated Successfully!");
                    })
                    .catch((error) => {
                        console.error("Failed to generate worksheet:", error);
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showErrorAlert("Failed to generate worksheet!");
                    });
                });
            
        },
        getInvoiceNumbers() {
            return this.commercialInvoices
                .map((invoice) => {
                    return `${invoice.invoice_number} \t ${invoice.invoice_date} \t`;
                })
                .join("\n");
        },
        async generateCustomsOvertimeFastAPI() {
            console.log("Generating Customs Overtime Fast API");
            SweetAlertConnector.overtimeAlert().then((response) => {
                console.log("Overtime Response: ", response);
                SweetAlertConnector.showLoadingAlert("Generating Customs Overtime...");
                FastAPI.generateCustomsOvertimePDF(
                    this.entryId,
                    response.cargoDetails,
                    response.startTime,
                    this.entryDetails?.entry_number
                )
                    .then(() => {
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("Customs Overtime Generated Successfully!");
                    })
                    .catch((error) => {
                        console.error("Failed to generate Customs Overtime:", error);
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showErrorAlert("Failed to generate Customs Overtime!");
                    });
            });
        },
        async generateSpecialExemptionFastAPITT() {
            console.log("Generating Special Exemption Fast API");

            try {
                const valueDeclarants = await FastAPI.getValueDeclarants();
                const specialExemptionDeclarations = await FastAPI.getSpecialExemptionDeclarations();
                console.log("Special Exemption Declarations: ", specialExemptionDeclarations);
                console.log("Value Declarants: ", valueDeclarants);
                SweetAlertConnector.form(specialExemptionDeclarations, valueDeclarants).then(async (selection) => {
                    console.log("Selection: ", selection);
                    SweetAlertConnector.showLoadingAlert("Generating Special Exemption...");
                    await FastAPI.generateSpecialExemptionPDF(
                        this.entryId,
                        parseInt(selection.select1),
                        parseInt(selection.select2),
                        selection.generatePreSigned,
                        this.entryDetails?.entry_number
                    );
                    console.log("Special Exemption Generated Successfully!");
                    SweetAlertConnector.closeCurrentAlert();
                    SweetAlertConnector.showSuccessAlert("Special Exemption Generated Successfully!");
                });
            } catch (error) {
                console.error("Failed to generate special exemption:", error);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showErrorAlert("Failed to generate special exemption!");
            }
        },
        async generateDepositPDFFastAPI() {
            console.log("Generating Deposit Form Fast API");
            SweetAlertConnector.depositReasonAlert().then((reasonResponse) => {
                console.log("Reason Response: ", reasonResponse);
                SweetAlertConnector.showLoadingAlert("Generating Deposit Form...");
                FastAPI.generateDepositPDF(this.entryId, reasonResponse, this.entryDetails?.entry_number)
                    .then(() => {
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("Deposit Form Generated Successfully!");
                    })
                    .catch((error) => {
                        console.error("Failed to generate Deposit Form:", error);
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showErrorAlert("Failed to generate Deposit Form!");
                    });
            });
        },
        async goToCustomsDeclarationPage() {
            const invoiceNumbers = this.getInvoiceNumbers();
            let netPrice = this.commercialInvoices.reduce((acc, invoice) => {
                return acc + Utilities.calculateInvoiceFinalTotal(invoice);
            }, 0);
            if (this.customsEntryForm.incoTerms === "CIF") {
                netPrice = netPrice + this.customsEntryForm.freightAmount + this.customsEntryForm.insuranceAmount;
            }
            console.log("Invoice Numbers: ", invoiceNumbers);
            console.log("Incoterms: ", this.customsEntryForm.incoTerms);
            console.log("Invoice Total: ", netPrice);
            console.log("Seller ID: ", this.customsEntryForm.exporterId);
            await this.saveAllDetails();
            
            this.$router.push({
                name: "CustomsDeclarationForm",
                params: {
                    entryId: this.entryId,
                },

            });
        },
        updateWaybillDate(event) {
            // Update entryDetails.waybill_date when the input changes
            this.entryDetails.waybill_date = event.target.value;
        },
        countryChange() {
            this.commercialInvoices.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    if (
                        !item.country_of_origin ||
                        item.country_of_origin.trim().length === 0 ||
                        item.country_of_origin === "N/A" ||
                        item.country_of_origin === undefined ||
                        item.country_of_origin === null
                    ) {
                        item.country_of_origin = this.entryDetails.country_of_origin;
                    }
                });
            });
        },
        async fetchCountries() {
            try {
                // const response = await fetch("https://restcountries.com/v3/all");
                // const data = await response.json();
                // this.countries = data.map((country) => ({
                //     name: country.name.common,
                //     code: country.cca2,
                // }));

                this.countries = await FastAPI.getCountries();
            } catch (error) {
                console.error("Failed to fetch countries:", error);
            }
        },
        formatInvoiceData(invoice) {
            return {
                invoice_id: invoice.invoice_id,
                invoice_number: invoice.invoice_number,
                invoice_date: invoice.invoice_date,
                invoice_total: invoice.invoice_total,
                sub_total: invoice.sub_total,
                supplier_name: invoice.vendor_name,
                taxed_amount: invoice.taxed_amount,
                supplier_address: invoice.supplier_address,
                purchase_order_number: invoice.purchase_order_number,
                inland: invoice.inland === "" ? "0.00" : parseFloat(invoice.inland),
                insurance: invoice.insurance === "" ? "0.00" : parseFloat(invoice.insurance),
                other_charges: invoice.otherCharges === "" ? "0.00" : parseFloat(invoice.otherCharges),
                entry_id: invoice.entry_id,
                currency: invoice.currency,
                rate_of_exchange: invoice.rate_of_exchange,
            };
        },
        getTotalNumberOfLines() {
            let total = 0;
            for (let invoice of this.commercialInvoices) {
                total += invoice.lines ? invoice.lines.length : 0;
            }
            this.totalNumberOfLines = total;
        },
        formatInvoiceLinesData(invoiceLines) {
            return invoiceLines.map((line) => {
                return {
                    invoice_line_id: line.invoice_line_id,
                    description: line.description,
                    quantity: parseFloat(line.quantity),
                    unit_price: parseFloat(line.unit_price),
                    extension_price: parseFloat(line.extension_price),
                    tariff_code: line.tariff_code,
                    product_code: line.product_code,
                    country_of_origin: line.country_of_origin,
                    cpc_code: line.cpcCode,
                    npc_code: line.npcCode,
                    vat_applicable: line.vatApplicable,
                };
            });
        },
        saveData() {
            console.log("Saving data...");
            this.loading = true;

            let promises = [this.updateEntryData(), this.updateWaybillData(), this.saveInvoiceDataOptimized()];
            if (this.linesToBeAdded.length > 0) {
                promises.push(this.addInvoiceLines());
            }
            if (this.invoicesToBeAdded.length > 0) {
                promises.push(this.addInvoices());
            }
            if (this.linesToBeDeleted.length > 0) {
                SweetAlertConnector.displayConfirmationDialog().then((result) => {
                    if (result.isConfirmed) {
                        promises.push(this.deleteInvoiceLines());
                    }

                    SweetAlertConnector.showLoadingAlert("Saving Data...");
                    console.log(result);
                    console.time("saveInvoiceData Execution Time");
                    Promise.all(promises)
                        .then(([entryResponse, waybillResponse]) => {
                            console.log("Entry details updated:", entryResponse.data);
                            console.log("Waybill details updated:", waybillResponse.data);
                            SweetAlertConnector.closeCurrentAlert();
                            console.timeEnd("saveInvoiceData Execution Time");
                            SweetAlertConnector.showSuccessAlert("All Data Saved successfully!");
                        })
                        .catch((error) => {
                            console.timeEnd("saveInvoiceData Execution Time");
                            console.error("There was an error saving the data!", error);
                            SweetAlertConnector.showErrorAlert("Failed to save Entry Data!");
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                });
            } else {
                SweetAlertConnector.showLoadingAlert("Saving Data...");
                Promise.all(promises)
                    .then(([entryResponse, waybillResponse]) => {
                        console.log("Entry details updated:", entryResponse.data);
                        console.log("Waybill details updated:", waybillResponse.data);
                        SweetAlertConnector.closeCurrentAlert();
                        SweetAlertConnector.showSuccessAlert("All Data Saved successfully!");
                    })
                    .catch((error) => {
                        console.error("There was an error saving the data!", error);
                        SweetAlertConnector.showErrorAlert("Failed to save Entry Data!");
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        toggleOptions() {
            console.log(this.freightType);
        },
        updateEntryFields(fieldName, value) {
            this.entryDetails[fieldName] = value;
        },

        // updateInvoiceFields(invoiceId, fieldName, value) {
        //   const invoice = this.commercialInvoices.find(invoice => invoice.invoice_id === invoiceId);
        //   invoice[fieldName] = value;
        // },
        updateInvoiceData(data, field, value) {
            data[field] = value;
            // console.log(data);
        },
        convertTinyIntoBoolean(tiny) {
            return tiny === 1 ? true : false;
        },
        async fetchAllDetails() {
            const api_url = "/api/";
            console.log("Fetching details");
            this.loading = true;
            this.dataRetrieved = false;
            try {
                const entryDetailsPromise = axios.get(`${api_url}entry-details/${this.entryId}`);
                const commercialInvoicesPromise = axios.get(`${api_url}commercial-invoices-with-lines/${this.entryId}`);

                const [entryDetailsResponse, commercialInvoicesResponse] = await Promise.all([
                    entryDetailsPromise,
                    commercialInvoicesPromise,
                ]);
                this.dataRetrieved = true;

                let waybillDate = entryDetailsResponse.data.waybill_date;
                let estimatedArrivalDate = entryDetailsResponse.data.estimated_arrival_date;

                const dateString = waybillDate ? Utilities.formatDateReadFromDB(waybillDate) : "";

                const estimateArrivalDateString = estimatedArrivalDate
                    ? Utilities.formatDateReadFromDB(estimatedArrivalDate)
                    : "";

                this.entryDetails = entryDetailsResponse.data;
                this.customsEntryForm.importerId = this.entryDetails.importer;
                this.customsEntryForm.exporterId = this.entryDetails.shipper;
                this.customsEntryForm.waybillNumber = this.entryDetails.waybill_number;
                this.customsEntryForm.waybillDate = dateString;
                this.waybillSourceURL = this.entryDetails.file_url;
                this.customsEntryForm.estimatedArrivalDate = estimateArrivalDateString;
                this.customsEntryForm.modeOfTransport = this.entryDetails.mode_of_transport;
                this.customsEntryForm.freightType = this.entryDetails.freight_type;
                this.customsEntryForm.vesselId = this.entryDetails.vessel;
                this.customsEntryForm.portOfDischargeId = this.entryDetails.port_of_discharge;
                this.customsEntryForm.netWeight = this.entryDetails.net_weight;
                this.customsEntryForm.grossWeight = this.entryDetails.gross_weight;
                this.customsEntryForm.numberOfPackages = this.entryDetails.package_quantity;
                this.customsEntryForm.packageType = this.entryDetails.package_type;
                this.customsEntryForm.marksAndNumbers = this.entryDetails.marks_and_numbers;
                this.customsEntryForm.totalCargoValue = this.entryDetails.invoice_total;
                this.customsEntryForm.freightAmount = this.entryDetails.freight_charge;
                this.customsEntryForm.insuranceAmount = this.entryDetails.insurance_amount ?? 0.0;
                this.customsEntryForm.otherCharges = this.entryDetails.other_charges ?? 0.0;
                this.customsEntryForm.freightCurrency = this.entryDetails.freight_currency || "USD";
                this.customsEntryForm.insuranceCurrency = this.entryDetails.insurance_currency || "USD";
                this.customsEntryForm.otherChargesCurrency = this.entryDetails.other_charges_currency || "USD";
                this.customsEntryForm.rateOfExchange = this.entryDetails.rate_of_exchange;
                this.customsEntryForm.countryOfOrigin = this.entryDetails.country_of_origin;
                this.customsEntryForm.countryOfFinalDestination = this.entryDetails.country_of_final_destination;
                this.customsEntryForm.regimeType = this.entryDetails.regimeType;

                this.customsEntryForm.regimeTypeCode = this.entryDetails.regimeTypeCode;
                this.customsEntryForm.depositAmount = this.entryDetails.deposit;
                this.customsEntryForm.containerCharges = this.entryDetails.container_charges;
                this.customsEntryForm.additionalCharges = this.entryDetails.additional_charges;
                this.customsEntryForm.localFee = this.entryDetails.local_fee;
                this.customsEntryForm.declarantId = this.entryDetails.declarant;
                this.customsEntryForm.incoTerms = this.entryDetails.incoterms;
                this.customsEntryForm.countryLastProvinance = this.entryDetails.country_last_provenance;
                this.customsEntryForm.tradingCountry = this.entryDetails.trading_country;

                this.classificationApproved = this.entryDetails.classification_approved;
                this.classificationApprovedBy = this.entryDetails.classification_approved_by;
                this.entryStatus = this.entryDetails.entry_status || "";
                console.log("Commercial Invoice Raw Data: ", commercialInvoicesResponse.data);
                this.commercialInvoices = commercialInvoicesResponse.data;
                this.retrieveInvoiceDocumentSourceURL();
                this.inializeTaxes();
                this.parseInvoiceData();
                this.getTotalNumberOfLines();
                this.getRegimeTypes();

                console.log("Entry details:", this.entryDetails);
                console.log("Customs Entry:", this.customsEntryForm);
                console.log("Commercial invoices:", this.commercialInvoices);
            } catch (error) {
                this.error = "Failed to load entry details: " + (error.response?.data || error.message);
                console.error("Failed to load entry details:", error);
                SweetAlertConnector.showErrorAlert("Failed to load Entry Details! Please Reach out to Administrator");
            } finally {
                this.loading = false;
            }
        },
        retrieveInvoiceDocumentSourceURL() {
            for (let invoice of this.commercialInvoices) {
                const entry = {};
                entry.name = invoice.invoice_number;
                entry.url = invoice.file_url;
                this.invoiceDocumentSourceURLs.push(entry);
            }
        },
        formatDate(myDate) {
            const date = new Date(myDate);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        formatDateForInput(dateString) {
            return moment(dateString).tz("UTC").format("YYYY-MM-DD");
        },
        parseFastAPIInvoiceData() {
            console.log("Parsing Invoice Data");
            this.commercialInvoices.forEach((invoice) => {
                invoice.vendor_name = invoice.supplier_name;
                invoice.other_charges = parseFloat(invoice.other_charges);
                invoice.invoice_date = this.formatDateForInput(invoice.invoice_date);
                invoice.lines.forEach((line) => {
                    line.quantity = parseFloat(line.quantity).toFixed(2);
                    line.unit_price = parseFloat(line.unit_price);
                    line.extension_price = parseFloat(line.extension_price).toFixed(2);
                    line.vatApplicable = this.convertTinyIntoBoolean(line.vat_applicable);
                    line.cpcCode = line.cpc_code;
                    line.npcCode = line.npc_code;
                });
            });
        },
        parseInvoiceData() {
            console.log("Parsing Invoice Data");
            this.commercialInvoices.forEach((invoice) => {
                invoice.vendor_name = invoice.supplier_name;
                invoice.otherCharges = parseFloat(invoice.other_charges);
                // invoice.invoice_date = this.formatDateForInput(invoice.invoice_date);
                invoice.lines.forEach((line) => {
                    line.quantity = parseFloat(line.quantity).toFixed(2);
                    line.unit_price = parseFloat(line.unit_price);
                    line.extension_price = parseFloat(line.extension_price).toFixed(2);
                    line.vatApplicable = this.convertTinyIntoBoolean(line.vat_applicable);
                    line.cpcCode = line.cpc_code;
                    line.npcCode = line.npc_code;
                });
            });
        },
        async getRegimeTypes() {
            await FastAPI.getRegimeTypes().then((data) => {
                this.customsEntryForm.regimeTypeOptions = data;
            });
        },
        async updateWaybillData() {
            this.waybill = {
                waybill_number: this.customsEntryForm.waybillNumber,
                waybill_date: this.customsEntryForm.waybillDate,
                estimated_arrival_date: this.customsEntryForm.estimatedArrivalDate,
                mode_of_transport: this.customsEntryForm.modeOfTransport,

                freight_type: this.customsEntryForm.freightType,
                marks_and_numbers: this.customsEntryForm.marksAndNumbers,
                package_type: this.customsEntryForm.packageType,
                cpc_code: this.entryDetails.cpc_code,
                npc_code: this.entryDetails.npc_code,
                package_quantity: this.customsEntryForm.numberOfPackages,
                country_of_origin: this.customsEntryForm.countryOfOrigin,
                country_of_final_destination: this.customsEntryForm.countryOfFinalDestination,
                importer: this.customsEntryForm.importerId,
                shipper: this.customsEntryForm.exporterId,
                vessel: this.customsEntryForm.vesselId,
                port_of_discharge: this.customsEntryForm.portOfDischargeId,
                entry_id: this.entryDetails.entry_id,
                waybill_id: this.entryDetails.waybill_id,
            };

            console.log("Saving Waybill Data: ", this.waybill);
            console.log(`${process.env.VUE_APP_API_BASE_URL}waybill/${this.waybill.waybill_id}`);
            return axios.put(`${process.env.VUE_APP_API_BASE_URL}waybill/${this.waybill.waybill_id}`, this.waybill);
        },

        async updateEntryData() {
            this.entry = {
                mawi_invoice: this.entryDetails.mawi_invoice,
                entry_date: this.entryDetails.entry_date,
                invoice_total: this.entryDetails.invoice_total,
                supplier_name: this.entryDetails.supplier_name,
                net_weight: this.customsEntryForm.netWeight,
                gross_weight: this.customsEntryForm.grossWeight,
                entry_number: this.entryDetails.entry_number,
                freight_charge: this.customsEntryForm.freightAmount,
                insurance_amount: this.customsEntryForm.insuranceAmount ?? 0.0,
                other_charges: this.customsEntryForm.otherCharges ?? 0.0,
                freight_currency: this.customsEntryForm.freightCurrency,
                insurance_currency: this.customsEntryForm.insuranceCurrency,
                other_charges_currency: this.customsEntryForm.otherChargesCurrency,
                rate_of_exchange: this.entryDetails.rate_of_exchange,
                consignee: this.entryDetails.consignee,
                declarant: this.customsEntryForm.declarantId,
                incoterms: this.customsEntryForm.incoTerms,
                regime_type: this.customsEntryForm.regimeType,
                deposit: this.customsEntryForm.depositAmount,
                container_charges: this.customsEntryForm.containerCharges,
                additional_charges: this.customsEntryForm.additionalCharges,
                local_fee: this.customsEntryForm.localFee,
                country_last_provenance: this.customsEntryForm.countryLastProvinance,
                trading_country: this.customsEntryForm.tradingCountry,
                classification_status: this.classificationStatus,
                classification_approved: this.classificationApproved,
                classification_approved_by: this.classificationApprovedBy,
                date_classified: this.dateClassified,
                entry_status: this.entryStatus,
            };

            console.log("Saving Entry", this.entry);
            return axios.put(
                `${process.env.VUE_APP_API_BASE_URL}customs-entry/${this.entryDetails.entry_id}`,
                this.entry
            );
        },

        async saveInvoiceData() {
            const updatePromises = this.commercialInvoices.map((invoice) => {
                const formattedInvoiceData = this.formatInvoiceData(invoice);
                const formattedInvoiceLinesData = this.formatInvoiceLinesData(invoice.lines);

                const requestData = {
                    invoiceData: formattedInvoiceData,
                    invoiceLines: formattedInvoiceLinesData,
                };

                console.log("Updating invoice and invoice lines:", requestData);

                // Store the promise without calling then() or catch() on it yet
                return axios.put(
                    `${process.env.VUE_APP_API_BASE_URL}update-invoice-and-lines/${formattedInvoiceData.invoice_id}`,
                    requestData
                );
            });

            return Promise.all(updatePromises);
        },
        async saveInvoiceDataOptimized() {
            try {
                // Format all invoices
                console.time("saveInvoiceData Execution Time");
                const formattedInvoices = this.commercialInvoices.map((invoice) => this.formatInvoiceData(invoice));

                // Format all invoice lines
                const formattedInvoiceLines = this.commercialInvoices.flatMap((invoice) =>
                    this.formatInvoiceLinesData(invoice.lines)
                );

                // Create request payloads
                const invoicesRequestData = { invoices: formattedInvoices };
                const invoiceLinesRequestData = { invoiceLines: formattedInvoiceLines };

                console.log("Updating multiple invoices:", invoicesRequestData);
                console.log("Updating multiple invoice lines:", invoiceLinesRequestData);

                // Send the update requests concurrently
                const [invoicesResponse, invoiceLinesResponse] = await Promise.all([
                    axios.put(`${process.env.VUE_APP_API_BASE_URL}update-multiple-invoices`, invoicesRequestData),
                    axios.put(
                        `${process.env.VUE_APP_API_BASE_URL}update-multiple-invoice-lines`,
                        invoiceLinesRequestData
                    ),
                ]);

                console.log("Invoices update response:", invoicesResponse.data);
                console.log("Invoice lines update response:", invoiceLinesResponse.data);

                console.timeEnd("saveInvoiceData Execution Time");

                return { invoicesResponse, invoiceLinesResponse };
            } catch (error) {
                console.error("Error updating invoices and invoice lines:", error);
                throw error;
            }
        },

        checkTHNCodes() {
            // Iterate through the array
            let invoiceData = this.commercialInvoices;
            invoiceData.forEach((invoice) => {
                invoice.lines.forEach((line) => {
                    // Check if the THN code is missing
                    if (
                        !line.tariff_code ||
                        line.tariff_code.trim() === "" ||
                        line.tariff_code === "N/A" ||
                        line.tariff_code === undefined ||
                        line.tariff_code === null
                    ) {
                        // Display an error message

                        return false; // Return false if any item is missing a THN code
                    }
                });
            });
            return true; // Return true if all items have a THN code
        },

        async sendRPARequest() {
            // const flowUrl = 'https://prod-127.westus.logic.azure.com:443/workflows/b219026190bf4867b84dd8498580e0b9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=P31ddYGAbtAtr7nEcJEc3TbOkgT75Ekk-viC_QcwcAg';
            const flowUrl =
                "https://prod-80.westus.logic.azure.com:443/workflows/5d29cdede5a040f4985afce5f5aa9036/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ncChiHbstv9-5mgsi9y5NE7Vd3jFNIq93iiVmXvhwqc";
            console.log("Sending RPA Request");
            console.log("Entry Details: ", this.entryDetails);
            console.log("URL ", flowUrl);

            const payload = {
                key: this.entryDetails?.entry_number,
            };

            try {
                const response = await fetch(flowUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                });

                if (response.ok) {
                    console.log("Flow triggered successfully");
                } else {
                    console.error("Failed to trigger flow:", response.statusText);
                }
            } catch (error) {
                console.error("Failed to trigger flow:", error);
            }
        },
    },
};

function verifyInvoiceData() {
    this.displayDocumentVerificationView = !this.displayDocumentVerificationView;
}

async function openWaybill() {
    const waybillURL = this.waybillSourceURL;
    if (!waybillURL || waybillURL.trim().length === 0) {
        SweetAlertConnector.showErrorAlert("No Waybill Found!");
        return;
    }
    console.log("Opening PDF");
    SweetAlertConnector.showLoadingAlert("Opening PDF...");

    try {
        //Automagically download the file to the users machine.
        const response = await fetch(waybillURL);
        if (!response.ok) {
            throw new Error("Failed to fetch the PDF");
        }

        // Create a Blob from the response
        const blob = await response.blob();
        const fileName = `${this.entryDetails?.entry_number} - Waybill.pdf`;

        // Automatically download the file
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showSuccessAlert("PDF Opened Successfully!");

        const newWindow = window.open("", "_blank", "width=800,height=600");

        if (newWindow) {
            newWindow.document.write(
                `<iframe src="${waybillURL}" style="width:100%; height:100%;" frameborder="0"></iframe>`
            );
            newWindow.document.title = "PDF Document";
        } else {
            console.error("Failed to open new window.");
        }
    } catch (error) {
        console.error("Error:", error);
        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showErrorAlert("Failed to open PDF!");
    }
}

async function mergeAndOpenPDFs() {
    console.log("Merging and Opening PDFs");
    SweetAlertConnector.showLoadingAlert("Merging and Opening Invoices...");
    const pdfs = this.invoiceDocumentSourceURLs.map((invoice) => invoice.url);
    console.log(pdfs);
    try {
        const response = await FastAPI.mergeFiles(pdfs);

        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showSuccessAlert("PDFs Merged Successfully!");

        const blob = new Blob([response], { type: "application/pdf" });
        //Automagically download the file to the users machine.
        const fileName = `${this.entryDetails?.entry_number} - Commercial Invoices.pdf`;

        // Automatically download the merged PDF
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        const url = URL.createObjectURL(blob);
        const newWindow = window.open("", "_blank", "width=800,height=600");

        if (newWindow) {
            newWindow.document.write(`<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`);
            newWindow.document.title = "Merged PDF";
        } else {
            console.error("Failed to open new window.");
        }
    } catch (error) {
        console.error("Error:", error);
        SweetAlertConnector.closeCurrentAlert();
        SweetAlertConnector.showErrorAlert("Failed to merge and open PDFs!");
    }
}
</script>

<style>
.badge-container {
    margin: 10px;
}
.error {
    color: red;
}
/* Add your styles here */
button {
    margin: 5px;
    cursor: pointer;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    /* text-align: center; */
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 5px;
    background: #fafafa;
    color: #bdbdbd;
    margin: 10px;
}

.dropzone.dragover {
    border-color: #3f51b5;
    background-color: #eeeeee;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}
.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
}

.dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown ul li {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown ul li:hover {
    background-color: #f0f0f0;
}
.fas.fa-file-pdf {
    color: #ff0000; /* Red color for PDF icon */
    margin-left: 10px;
}
.valid-commodity-code {
    color: #09ba09;
    font-weight: bold;
}

.invalid-commodity-code {
    color: red;
    font-weight: bold;
}
.green-icon {
    color: green;
    font-size: 20px; /* Make the icon bigger */
}

.icon-button {
    background: none;
    border: 1px solid green; /* Add a green border */
    padding: 5px; /* Add some padding */
    margin-left: 5px; /* Adds some space between the text and the button */
    cursor: pointer;
    border-radius: 5px; /* Round the edges of the button */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.icon-button:hover {
    background-color: green; /* Change background to green on hover */
}

.icon-button:hover .green-icon {
    color: white; /* Change icon color to white on hover */
}

.icon-button:focus {
    outline: none; /* Remove the default outline */
}
.custom-switch .form-check-input {
    transform: scale(1.5); /* Increase the size of the switch */
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center; /* Center the checkbox vertically */
}
.inline-buttons {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust the gap as needed */
}
</style>
