<template>
    <div id="app">
        <div class="page-header-info-container">
            <div>
                <button type="button" class="btn btn-primary upload-button" @click="verifyInvoiceData">
                    Invoice Split Screen
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="mergeAndOpenPDFs">
                    Open Invoices
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="openWaybill">Open Waybill</button>
                <button type="button" class="btn btn-primary upload-button" @click="displayCPCNPCAlert">
                    Set All CPC & NPC
                </button>
                <button type="button" class="btn btn-primary upload-button" @click="displaySetAllCountriesAlert">
                    Set All Countries
                </button>
                <!-- <button type="button" class="btn btn-primary upload-button" @click="addSupplier('Bruce Banner 1')">Test Supplier </button> -->
                <!-- <button type="button" class="btn btn-primary upload-button" @click="openWaybill">Open Waybill</button> -->
                <!-- <button type="button" class="btn btn-primary upload-button" @click="displayCPCNPCAlert">Set All CPC & NPC</button> -->
            </div>
            <div>
                <div class="badge-container">
                    <h3>
                        <span
                            :class="[
                                'badge',
                                {
                                    'bg-success': classificationStatus === 'Classification Completed',
                                    'bg-warning text-dark': classificationStatus === 'Classification In Progress',
                                    'bg-danger': classificationStatus === 'Not Classified',
                                },
                                'fixed-badge',
                            ]"
                        >
                            {{ this.classificationStatus }}
                        </span>
                    </h3>
                </div>
            </div>
        </div>

        <VerifyDocumentData
            :invoices="invoiceDataArray"
            :customsEntryForm="customsEntryForm"
            v-if="displayDocumentVerificationView"
            :pdfFiles="pdfFiles"
        />

        <div v-if="!displayDocumentVerificationView">
            <h2>New Entry</h2>
            <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2">
                    <input
                        type="text"
                        class="form-control"
                        v-model="referenceNumber"
                        placeholder="Enter Reference Number"
                        @input="validateInput"
                    />
                </div>
                <div class="col-md-5"></div>
            </div>
            <div>
                <div
                    class="dropzone"
                    @dragover.prevent="dragOver"
                    @dragenter.prevent="dragEnter"
                    @dragleave.prevent="dragLeave"
                    @drop.prevent="handleDrop"
                >
                    Please Upload Shipment Commercial Invoices and Waybills
                    <br />
                    <input
                        type="file"
                        multiple
                        @change="handleFileUpload"
                        style="display: none"
                        accept="application/pdf"
                        ref="invoices"
                    />
                    <button type="button" class="btn btn-primary" @click="triggerFileInput('invoices')">
                        Choose Files for Invoices
                    </button>
                    <input
                        type="file"
                        @change="handleWaybillUpload"
                        style="display: none"
                        accept="application/pdf"
                        ref="waybill"
                    />
                    <button type="button" class="btn btn-primary upload-button" @click="triggerFileInput('waybill')">
                        Choose Files for Waybills
                    </button>
                    <!-- <button type="button" class="btn btn-primary upload-button" @click="uploadFiles">
                        Upload Files AWS
                    </button> -->
                    <button
                        type="button"
                        class="btn btn-success upload-button"
                        @click="uploadInvoicesAndWaybills('/document-analysis/invoice', 'CLAUDE_LLM')"
                    >
                        Upload Files LLM
                    </button>
                    <!-- <button type="button" class="btn btn-primary upload-button" @click="uploadInvoicesAndWaybills()">
                        Upload Files GABRIEL
                    </button> -->
                </div>
                <div class="file-list-container">
                    <h3>Uploaded Files</h3>
                    <div class="row">
                        <div class="col-md-6">
                            <h4>Invoices</h4>
                            <ul>
                                <li v-for="(file, index) in selectedFiles" :key="index">
                                    {{ file.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <h4>Waybills</h4>
                            <ul>
                                <li v-for="(file, index) in waybillFiles" :key="index">
                                    {{ file.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="container-fluid text-center">
                <div class="row">
                    <div class="col">
                        <FileDragAndDrop />
                    </div>
                    <div class="col">
                        <FileDragAndDrop />
                    </div>
                </div>
            </div> -->
            <CustomsEntryForm
                v-if="selectedFiles.length > 0"
                :customsEntryForm="customsEntryForm"
                :createMode="true"
                @update-customs-entry-form="customsEntryForm = $event"
            />
            <DocumentTableView
                v-if="selectedFiles.length > 0"
                :invoiceDataArray="invoiceDataArray"
                :customsEntryForm="customsEntryForm"
                :documentVerificationFormat="false"
                :linesToBeDeleted="linesToBeDeleted"
                :invoicesToBeDeleted="invoicesToBeDeleted"
                ref="documentTableView"
            />

            <div v-if="selectedFiles.length > 0">
                <h3>Entry Summary</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Number of Lines Read</th>
                            <th>Total Invoices Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ this.totalNumberOfLines }}</td>
                            <td>{{ this.totalFOBValue }}</td>
                        </tr>
                        <tr>
                            <td>
                                <button type="button" class="btn btn-primary" @click="saveDataFastAPI()">
                                    Save Entry
                                </button>
                            </td>
                            <!-- <td><button type="button" class="btn btn-primary" :disabled="thnCodesCompleted" @click="generateWorkSheet">Generate WorkSheet</button></td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import * as Utilities from "@/composables/Utilities";
import * as SweetAlertConnector from "../composables/SweetAlertConnector";
import * as Configuration from "@/composables/BrokerConfiguration";
import VerifyDocumentData from "./VerifyDocumentData.vue";
import DocumentTableView from "./DocumentTableView.vue";
import FileDragAndDrop from "./FileDragAndDrop.vue";
import { PDFDocument } from "pdf-lib";
import CustomsEntryForm from "./CustomsEntry/CustomsEntryForm.vue";
import { FAST_API_RESPONSE_STATUS_CODES } from "../constants";
import { CLASSIFICATION_STATUS, INVOICE_AI_MODELS, DOCUMENT_ANALYSIS_STATUSES } from "../constants";
import { isAuthenticated } from "@/composables/auth";

import * as FastAPI from "@/composables/FastAPI";

export default {
    /* eslint-disable */
    name: "App",
    components: {
        CustomsEntryForm,
        VerifyDocumentData,
        DocumentTableView,
        FileDragAndDrop,
    },
    data() {
        return {
            baseCurrencyId: 0,
            currencies: [],
            displayDocumentVerificationView: false,
            referenceNumber: "",
            selectedFiles: [],
            waybillFiles: [],
            pdfFiles: [],
            originalInvoiceDataArray: [],
            invoiceDataArray: [],
            linesToBeDeleted: [],
            invoicesToBeDeleted: [],
            isDragOver: false,
            isValidReference: false,
            invoicesDataExtractionCompleted: false,
            waybillDataExtractionCompleted: false,
            classificationCompleted: false,

            waybillNumber: "",
            waybillDate: new Date().toISOString().substr(0, 10),
            modeOfTransport: "",

            freightType: "",
            marksAndNumbers: "As Addressed",
            countryOfOrigin: "",
            cpcCode: "4000",
            npcCode: "000",

            kindOfPackage: "CT",
            numberOfPackages: "",

            consignee: "",
            shipper: "",
            supplier: "",
            totalCargoValue: 0.0,
            totalNumberOfLines: 0,
            freightCharge: 0.0,
            rateOfExchange: 1.0,
            netWeight: 0.0,
            grossWeight: 0.0,

            waybillId: null,
            entryId: null,
            invoiceFileUrls: [],

            editableContent: "0",

            countries: [],
            modeOfTransportOOptions: ["Air", "Ocean"],
            freightTypeOptions: ["FCL", "LCL", "Bulk", "Break Bulk"],
            cpcCodeOptions: ["4000"],
            npcCodeOptions: ["000"],
            packageTypeOptions: [],

            showModeofTransportDropdown: false,

            invoicingProcessingStart: null,
            invoicingProcessingEnd: null,

            waybillProcessingStart: null,
            waybillProcessingEnd: null,
            invoiceModelUtilized: "",

            classificationProcessingStart: null,
            classificationProcessingEnd: null,

            customsEntryForm: {
                exporters: [],
                importers: [],
                vessels: [],
                ports: [],
                declarants: [],
                incotermsList: [],
                ratesOfExchange: [],
                freightTypeOptions: [],
                importerId: null,
                exporterId: null,
                importerName: "",
                exporterName: "",
                importerTaxIdentificationNumber: "",
                exporterTaxIdentificationNumber: "",
                declarantId: "",
                declarantTaxIdentificationNumber: "",
                modeOfTransport: "",
                freightType: null,
                waybillNumber: "",
                waybillDate: null,
                estimatedArrivalDate: "",
                vesselId: null,
                vesselName: "",
                incoTerms: "",
                portOfDischargeId: null,
                portOfDischargeName: "",
                portOfLoadingId: null,
                portOfLoadingName: "",
                netWeight: 0.0,
                grossWeight: 0.0,
                numberOfPackages: 0,
                packageType: null,
                packageTypes: [],
                countryList: [],
                marksAndNumbers: "As Addressed",
                totalCargoValue: 0.0,
                freightAmount: 0.0,
                insuranceAmount: 0.0,
                otherCharges: 0.0,
                freightCurrency: null,
                insuranceCurrency: null,
                otherChargesCurrency: null,
                insuranceRateOfExchange: 0.0,
                otherChargesRateOfExchange: 0.0,
                freightRateOfExchange: 0.0,
                freightExchangeRateId: null,
                insuranceExchangeRateId: null,
                otherChargesExchangeRateId: null,
                rateOfExchange: 0.0,
                regimeType: "",
                regimeTypeCode: "",
                regimeTypeOptions: [],
                depositAmount: 0.0,
                containerCharges: 0.0,
                additionalCharges: 0.0,
                localFee: 0.0,
                countryOfOrigin: null,
                countryOfFinalDestination: null,
                countryLastProvinance: null,
                tradingCountry: null,
                userDetails: null,
                locationOfGoodsId: null,
                manifestReference: "",
                deliveryPlace: "",
            },
        };
    },
    watch: {
        "customsEntryForm.countryOfOrigin"(newCountry, oldCountry) {
            this.invoiceDataArray.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    if (!item.country_of_origin_id) {
                        item.country_of_origin_id = newCountry;
                    }
                });
            });
        },
        "customsEntryForm.regimeType"(newRegime) {
            this.invoiceDataArray.forEach((invoice) => {
                invoice.lines.forEach((item) => {
                    const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                        (regime) => regime.regimeTypeId === newRegime
                    );
                    if (regimeData) {
                        item.cpcCode = regimeData.cpcCodes[0].cpcId;
                    }
                });
            });
        },
        "customsEntryForm.waybillDate"(newDate) {
            this.updateRates(newDate);
        },
        invoiceDataArray: {
            handler: function (invoices) {
                if (this.customsEntryForm.ratesOfExchange.length > 0) {
                    invoices.forEach((invoice) => {
                        this.$watch(
                            () => invoice.currency,
                            (newCurrency) => {
                                if (newCurrency) {
                                    invoice.rate_of_exchange = this.customsEntryForm.ratesOfExchange.find(
                                        (rate) => rate.base_currency === newCurrency
                                    ).rate;
                                }
                            },
                            { deep: true }
                        );
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },

    setup() {
        return {};
    },
    mounted() {
        this.updateEntryFields("marksAndNumbers", "As Addressed");
        this.getTotalNumberOfLines();
        this.getRegimeTypes();
        this.getCurrencies();
        this.testAuthentication();
    },
    computed: {
        classificationStatus() {
            if (this.invoiceDataArray.length === 0) return CLASSIFICATION_STATUS.NOT_CLASSIFIED;
            return this.thnCodesCompleted ? CLASSIFICATION_STATUS.NOT_CLASSIFIED : CLASSIFICATION_STATUS.COMPLETED;
        },
        thnCodesCompleted() {
            return this.invoiceDataArray.some((invoice) => {
                return invoice.lines.some((line) => !line.tariff_code || line.tariff_code.trim() === "");
            });
        },
        totalFOBValue() {
            return this.invoiceDataArray
                .reduce((acc, invoice) => acc + parseFloat(invoice.invoice_total), 0)
                .toFixed(2);
        },
        entryButtonDisabled() {
            return !(this.hasInvoiceNumber || this.waybillNumber.trim().length > 0 || this.consignee.trim().length > 0);
        },
        hasInvoiceNumber() {
            for (let invoice of this.invoiceDataArray) {
                if (invoice.invoice_number && invoice.invoice_number.trim().length > 0) {
                    console.log("Invoice Number Found");
                    return true;
                }
            }
            return false;
        },
        availableCPCCodes() {
            if (this.customsEntryForm.regimeTypeCode !== undefined && this.customsEntryForm.regimeTypeCode !== null) {
                const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                    (regime) => regime.regimeTypeCode === this.customsEntryForm.regimeTypeCode
                );
                if (regimeData) {
                    return regimeData.cpcCodes;
                } else return [];
            } else {
                return this.customsEntryForm.regimeTypeOptions.flatMap((regime) => regime.cpcCodes);
            }
        },
        availableNPCCodes() {
            const npcCodesArray = [];

            this.availableCPCCodes.forEach((item) => {
                if (item.npcCodes && Array.isArray(item.npcCodes)) {
                    npcCodesArray.push(...item.npcCodes);
                }
            });

            return npcCodesArray;
        },
    },
    methods: {
        verifyInvoiceData,
        displaySetAllCountriesAlert(){
            SweetAlertConnector.showCountrySelectionAlert(this.customsEntryForm.countryList).then((result) => {
                console.log("Selected Country", result);
                if (result === undefined) return;
                this.invoiceDataArray.forEach((invoice) => {
                    invoice.lines.forEach((line) => {
                        line.country_of_origin_id = parseInt(result);
                    });
                });
                SweetAlertConnector.showSuccessAlert("Updated All Country of Origin Lines");
            });
        },
        displayCPCNPCAlert() {
            SweetAlertConnector.showCPCNPCAlert(this.availableCPCCodes).then((result) => {
                console.log("Selectd Codes", result);
                if (result === undefined) return;
                this.commercialInvoices = Utilities.setCPCANCAllLines(
                    this.invoiceDataArray,
                    parseInt(result.cpcCode),
                    parseInt(result.npcCode)
                );
                SweetAlertConnector.showSuccessAlert("Updated All CPC & NPC Lines");
            });
        },
        async testAuthentication() {
            const result = isAuthenticated();
            console.log("Authentication Test", result);
        },
        async getPackageTypes() {
            this.packageTypeOptions = await FastAPI.getPackageTypes();
        },
        async setCPCANCAllLines(cpcId, ancId) {
            Utilities.setCPCANCAllLines();
        },
        async getCurrencies() {
            try {
                this.currencies = await FastAPI.getCurrencies();
                this.customsEntryForm.freightCurrency = this.currencies.find((currency) => currency.code === "USD").id;
                this.customsEntryForm.insuranceCurrency = this.currencies.find((currency) => currency.code === "USD").id;
                this.customsEntryForm.otherChargesCurrency = this.currencies.find((currency) => currency.code === "USD").id;
                this.baseCurrencyId = this.currencies.find((currency) => currency.code === "TTD").id;
            } catch (error) {
                console.error("Error fetching currencies", error);
            }
        },
        async saveWaybillFastAPI() {
            let waybillFileURL = "";

            const waybillObject = Utilities.filterNullValues({
                waybill_number: this.customsEntryForm.waybillNumber,
                waybill_date: this.customsEntryForm.waybillDate === '' ? null : this.customsEntryForm.waybillDate,
                shipped_on_board_date: this.customsEntryForm.waybillDate === '' ? null : this.customsEntryForm.waybillDate,
                freight_type: this.customsEntryForm.freightType,
                mode_of_transport_id: this.customsEntryForm.modeOfTransport,
                freight_type_id: this.customsEntryForm.freightType,
                voyage_flight_number: "",
                description_of_goods: "",
                country_of_origin_id: this.customsEntryForm.countryOfOrigin,
                package_type_id: this.customsEntryForm.packageType,
                package_quantity: this.customsEntryForm.numberOfPackages,
                vessel_id: this.customsEntryForm.vesselId,
                gross_weight: this.customsEntryForm.grossWeight,
                importer_id: this.customsEntryForm.importerId,
                exporter_id: this.customsEntryForm.exporterId,
                office_of_destination_id: this.customsEntryForm.portOfDischargeId,
                office_of_entry_exit_id: this.customsEntryForm.portOfLoadingId,
                location_of_goods_id: this.customsEntryForm.locationOfGoodsId,
                file_url: waybillFileURL,
                international_freight: Utilities.filterNullValues({
                    amount: this.customsEntryForm.freightAmount,
                    currency_id: this.customsEntryForm.freightCurrency,
                    rate_of_exchange_id: this.customsEntryForm.freightExchangeRateId,
                }),
                insurance: Utilities.filterNullValues({
                    amount: this.customsEntryForm.insuranceAmount,
                    currency_id: this.customsEntryForm.insuranceCurrency,
                    rate_of_exchange_id: this.customsEntryForm.insuranceExchangeRateId,
                }),
                other_charges: Utilities.filterNullValues({
                    amount: this.customsEntryForm.otherCharges,
                    currency_id: this.customsEntryForm.otherChargesCurrency,
                    rate_of_exchange_id: this.customsEntryForm.otherChargesExchangeRateId,
                }),
            });
            console.log("Mode of Transport: ", this.customsEntryForm.modeOfTransport);
            console.log("Waybill Object: ", waybillObject);

            try {
                const response = await FastAPI.saveWaybill(waybillObject);
                console.log("Waybill Response: ", response.data);
                if (response.status === FAST_API_RESPONSE_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                    const errorMessage = Utilities.generateErrorMessage(response.data);
                    console.error("Error saving waybill:", errorMessage);
                    throw new Error(errorMessage);
                }
                const waybillId = response.data.id;
                if (this.waybillFiles.length > 0) {
                    const waybillFile = this.waybillFiles[0];
                    const waybillFileName = `${this.referenceNumber}-${waybillId}-Waybill.${waybillFile.name
                        .split(".")
                        .pop()}`;
                    const renamedFile = new File([waybillFile], waybillFileName, { type: waybillFile.type });
                    const fileUrl = await FastAPI.uploadFiles([renamedFile]);
                    console.log("Waybill File URL: ", fileUrl);
                    waybillFileURL = fileUrl[0];
                    const updatedWaybillObject = {
                        ...waybillObject,
                        file_url: waybillFileURL,
                    };
                    await FastAPI.updateWaybill(waybillId, updatedWaybillObject);
                }
                return waybillId;
            } catch (error) {
                console.error("Failed to save waybill:", error);
                SweetAlertConnector.showErrorAlert(error);
                throw error;
            }
        },
        async saveEntryFastAPI(waybillId) {
            console.log("Saving Entry", waybillId);
            const entryObject = Utilities.filterNullValues({
                entry_date: Utilities.getDateStringInputField(),
                entry_number: this.referenceNumber,
                declarant_id: this.customsEntryForm.declarantId,
                regime_type_id: this.customsEntryForm.regimeType,
                waybill_id: waybillId,
                local_charge: this.customsEntryForm.localFee,
                deposit_fee: this.customsEntryForm.depositAmount,
                container_charges: this.customsEntryForm.containerCharges,
                country_of_first_destination_id: this.customsEntryForm.countryLastProvinance,
                trading_country_id: this.customsEntryForm.tradingCountry,
                country_of_export_id: this.customsEntryForm.countryOfOrigin,
                country_of_destination_id: this.customsEntryForm.countryOfFinalDestination,
                delivery_terms_id: this.customsEntryForm.incoTerms,
                delivery_place: this.customsEntryForm.deliveryPlace,
                manifest_reference: this.customsEntryForm.manifestReference,
                status: "STORED_AND_NOT_CLASSIFIED",
            });
            console.log("Entry Object: ", entryObject);
            return FastAPI.createCustomsEntry(entryObject)
                .then((response) => {
                    console.log("Entry Response: ", response);
                    return response.id;
                })
                .catch((error) => {
                    console.error("Failed to save entry:", error);
                    SweetAlertConnector.showErrorAlert("Failed to save entry");
                    throw error;
                });
        },
        findCpcNpcRelationId(cpcId, npcId) {
            // Iterate through each main object in the data array
            for (let item of this.availableCPCCodes) {
                // Check if the current item's cpcId matches the given cpcId
                if (item.cpcId === cpcId) {
                    // If it matches, iterate through its npcCodes array
                    for (let npc of item.npcCodes) {
                        // Check if the current npc's npcId matches the given npcId
                        if (npc.npcId === npcId) {
                            // If it matches, return the cpcNpcRelationId
                            return npc.cpcNpcRelationId;
                        }
                    }
                }
            }
            // Return null or an appropriate value if no match is found
            return null;
        },
        async saveInvoiceData(entryId) {
            if (this.invoiceFileUrls.length === 0) {
                try {
                    const invoiceFiles = this.selectedFiles.map((file, index) => {
                        const fileName = `${this.referenceNumber}-${entryId}-${index}-CommercialInvoice.${file.name
                            .split(".")
                            .pop()}`;
                        const renamedFile = new File([file], fileName, { type: file.type });
                        return renamedFile;
                    });

                    this.invoiceFileUrls = await FastAPI.uploadFiles(invoiceFiles);
                    console.log("Invoice File URLs: ", this.invoiceFileUrls);
                } catch (error) {
                    console.error("Failed to save invoices:", error);
                    SweetAlertConnector.showErrorAlert("Failed to save invoices");
                    throw error;
                }
            } else {
                console.log("Invoice files already saved. Using the previous files");
            }
            const invoiceData = this.invoiceDataArray.map((invoice, index) => {
                return {
                    invoice_number: invoice.invoice_number,
                    invoice_date: invoice.invoice_date,
                    invoice_total: invoice.invoice_total,
                    inland: invoice.inland,
                    insurance: invoice.insurance,
                    other_charges: invoice.otherCharges,
                    rate_of_exchange_id: invoice.rateOfExchangeId,
                    supplier_id: invoice.supplier_id,
                    currency_id: invoice.currency,
                    customs_entry_id: entryId,
                    purchase_order_number: "", // TODO
                    file_url: this.invoiceFileUrls[index],
                    page_count: invoice.page_count,
                    invoice_lines: invoice.lines.map((line) => {
                        const cpcNpcRelationId = this.findCpcNpcRelationId(line.cpcCode, line.npcCode);
                        return {
                            product_code: line.product_number || "",
                            quantity: line.quantity,
                            unit_price: line.unit_price,
                            description: line.description,
                            classification_description: line.classification_description,
                            extension_price: line.extension_price,
                            discount_price: 0, // TODO
                            trade_agreement_id: line.tradeAgreement,
                            commodity_code_id: line.commodityCodeId,
                            country_of_origin_id: line.country_of_origin_id,
                            cpc_npc_code_id: cpcNpcRelationId,
                            package_type_id: this.customsEntryForm.packageType,
                            package_quantity: this.customsEntryForm.numberOfPackages,
                            marks_and_numbers: this.customsEntryForm.marksAndNumbers,
                        };
                    }),
                };
            });
            console.log("Invoice Data: ", invoiceData);
            return FastAPI.createCommercialInvoices(invoiceData)
                .then((response) => {
                    console.log("Invoice Response: ", response.data);
                    if (response.status === FAST_API_RESPONSE_STATUS_CODES.SUCCESS) {
                        return response.data;
                    }
                    if (response.status === FAST_API_RESPONSE_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                        const errorMessage = Utilities.generateErrorMessage(response.data);
                        throw new Error(errorMessage);
                    } else {
                        throw new Error("Failed to save invoices");
                    }
                })
                .catch((error) => {
                    console.error("Failed to save invoices:", error);
                    SweetAlertConnector.showErrorAlert(error);
                    throw error;
                });
        },
        async saveCommodityCodePerformanceMetrics(invoiceResponse) {
            const allCommodityCodePerformanceMetrics = [];
            this.invoiceDataArray.forEach((invoice, invoiceIndex) => {
                invoice.lines.forEach((line, lineIndex) => {
                    const relatedInvoiceLine = invoiceResponse[invoiceIndex].invoice_lines[lineIndex];
                    const llmCommodityId = line.llm_code_id;
                    const llmConfidenceScore = line.llm_code_confidence_score;
                    if (llmCommodityId !== null && llmCommodityId !== undefined) {
                        const performanceMetric = {
                            model_code_id: llmCommodityId,
                            commercial_invoice_line_id: relatedInvoiceLine.id,
                            confidence_score: llmConfidenceScore,
                        };
                        allCommodityCodePerformanceMetrics.push(performanceMetric);
                    }
                });
            });
            console.log("Commodity Code Performance Metrics: ", allCommodityCodePerformanceMetrics);
            try {
                if (allCommodityCodePerformanceMetrics.length > 0) {
                    const response = await FastAPI.postClassificationStatistics(allCommodityCodePerformanceMetrics);
                    console.log("Commodity Code Performance Metrics Response: ", response);
                    return response;
                } else return;
            } catch (error) {
                console.error("Failed to save commodity code performance metrics:", error);
                SweetAlertConnector.showErrorAlert("Failed to save commodity code performance metrics");
                throw error;
            }
        },
        async saveLatencyPerformanceData(entryId) {
            if(this.classificationProcessingStart === null ||this.classificationProcessingEnd === null)
                return
            const performanceData = {
                time_request_sent: this.invoicingProcessingStart.toISOString(),
                time_document_analysis_response_received: this.invoicingProcessingEnd.toISOString(),
                time_response_received_full: this.classificationProcessingEnd.toISOString(),
                status: DOCUMENT_ANALYSIS_STATUSES.INVOICE_LATENCY_PERFORMANCE,
                page_count: this.invoiceDataArray.reduce((acc, invoice) => acc + (invoice.page_count || 0), 0),
                customs_entry_id: entryId,
            };
            
            const classificationPerformanceData = {
                time_request_sent: this.classificationProcessingStart.toISOString(),
                time_document_analysis_response_received: this.classificationProcessingEnd.toISOString(),
                time_response_received_full: this.classificationProcessingEnd.toISOString(),
                status: DOCUMENT_ANALYSIS_STATUSES.CLASSIFICATION_LATENCY_PERFORMANCE,
                customs_entry_id: entryId,
            };
            console.log("Performance Data: ", performanceData);
            try {
                const promises = [
                    FastAPI.saveLatencyPerformanceData(performanceData),
                    FastAPI.saveLatencyPerformanceData(classificationPerformanceData),
                ];
                if(this.waybillProcessingStart != null || this.waybillProcessingEnd != null){
                    const waybillPerformanceData = {
                        time_request_sent: this.waybillProcessingStart.toISOString(),
                        time_document_analysis_response_received: this.waybillProcessingEnd.toISOString(),
                        time_response_received_full: this.waybillProcessingEnd.toISOString(),
                        status: DOCUMENT_ANALYSIS_STATUSES.WAYBILL_LATENCY_PERFORMANCE,
                        customs_entry_id: entryId,
                    };
                    promises.push(FastAPI.saveLatencyPerformanceData(waybillPerformanceData),);
                }
                
                const response = await Promise.all(promises);
                // const response = await FastAPI.saveLatencyPerformanceData(performanceData);
                console.log("Latency Performance Data Response: ", response);
                return response;
            } catch (error) {
                console.error("Failed to save latency performance data:", error);
                SweetAlertConnector.showErrorAlert("Failed to save latency performance data");
                throw error;
            }
        },
        async saveManualExemptions(invoiceResponse) {
            const allExemptionObjects = [];
            this.invoiceDataArray.forEach((invoice, invoiceIndex) => {
                invoice.lines.forEach((line, lineIndex) => {
                    if (line.manualExemption === true) {
                        console.log("Manual Exemption Found");
                        const exemptionObjects = line.exemptionObjects;
                        if (
                            exemptionObjects !== null &&
                            exemptionObjects !== undefined &&
                            Array.isArray(exemptionObjects)
                        ) {
                            exemptionObjects.forEach((exemptionObject) => {
                                const relatedInvoiceLine = invoiceResponse[invoiceIndex].invoice_lines[lineIndex];
                                exemptionObject.commercial_invoice_line_id = relatedInvoiceLine.id;
                                allExemptionObjects.push(exemptionObject);
                            });
                        }
                        console.log("Exemption Objects: ", exemptionObjects);
                    }
                });
            });
            try {
                if (allExemptionObjects.length > 0) {
                    const response = await FastAPI.createManualExemptions(allExemptionObjects);
                    console.log("Manual Exemption Response: ", response);
                    return;
                } else return;
            } catch (error) {
                console.error("Failed to save manual exemptions:", error);
                SweetAlertConnector.showErrorAlert("Failed to save manual exemptions");
                throw error;
            }
        },
        async saveDataFastAPI() {
            console.log("Savng Data FAST api");
            try {
                SweetAlertConnector.asyncLoadingAlert("Saving Data...");
                if (this.waybillId == null) {
                    this.waybillId = await this.saveWaybillFastAPI();
                }
                if (this.entryId === null) {
                    this.entryId = await this.saveEntryFastAPI(this.waybillId);
                    console.log("Saved Entry", this.entryId);
                }

                const invoiceResponse = await this.saveInvoiceData(this.entryId);
                // await this.saveManualExemptions(invoiceResponse);
                // await this.saveCommodityCodePerformanceMetrics(invoiceResponse);
                await Promise.all([
                    this.saveManualExemptions(invoiceResponse),
                    this.saveCommodityCodePerformanceMetrics(invoiceResponse),
                    this.saveLatencyPerformanceData(this.entryId),
                ]);
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert("Data Saved Successfully");
                this.redirectToEntryDetails(this.entryId);
            } catch (error) {
                // SweetAlertConnector.showErrorAlert("Failed to save data");
                console.error("Failed to save data:", error);
            }
        },
        dataExtractionLoadingMessage() {
            if (this.invoicesDataExtractionCompleted && this.waybillDataExtractionCompleted) {
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.showSuccessAlert("Data Extraction Completed");
                if (!this.classificationCompleted) {
                    SweetAlertConnector.asyncLoadingAlert("Classifying Invoice Items...");
                }
            } else if (this.invoicesDataExtractionCompleted && !this.waybillDataExtractionCompleted) {
                // SweetAlertConnector.updateCurrentAlert("Invoice data read. Still Reading Waybill Data");
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.asyncLoadingAlert("Invoice Data Read. Reading Waybill Data...");
            } else if (!this.invoicesDataExtractionCompleted && this.waybillDataExtractionCompleted) {
                // SweetAlertConnector.updateCurrentAlert("Waybill data read. Still Reading Invoice Data");
                SweetAlertConnector.closeCurrentAlert();
                SweetAlertConnector.asyncLoadingAlert("Waybill Data Read. Reading Invoice Data...");
            }
        },
        triggerFileInput(refName) {
            console.log("Triggering File Input");
            this.$refs[refName].click();
        },
        updateTariffCode(data, itemIndex, field, value, confidenceScore = 100) {
            // console.log("Updating Tariff Code");
            // console.log("Item Index: ", itemIndex);
            this.updateItem(data, itemIndex, field, value);
            if (value === undefined || value === null || value.trim().length === 0 || value === "N/A") {
                data.icdCharge = 0;
                data.vatCharge = 0;
                return;
            }
            FastAPI.getCommodityCodeCharges(value.replace(/\./g, ""))
                .then((chargesResponse) => {
                    const commodityCodeId = chargesResponse.id;
                    let icdObject = Utilities.findChargeObject(chargesResponse, "import custom duty");
                    const icdCharge = icdObject === undefined ? 0 : parseFloat(icdObject.charge_amount);

                    const vatObject = Utilities.findChargeObject(chargesResponse, "value added tax");
                    const vatCharge = vatObject === undefined ? 0 : parseFloat(vatObject.charge_amount);

                    data.lines[itemIndex]["commodityCodeId"] = commodityCodeId;
                    data.lines[itemIndex]["llm_code_id"] = commodityCodeId;
                    data.lines[itemIndex]["llm_code_confidence_score"] = confidenceScore;
                    data.lines[itemIndex]["icdCharge"] = icdCharge;
                    data.lines[itemIndex]["vatCharge"] = vatCharge;
                    data.lines[itemIndex]["commodityCodeStatus"] = "valid";
                    data.lines[itemIndex]["commodity_description"] = chargesResponse.description;
                })
                .catch((error) => {
                    console.error("Failed to get commodity charges:", error);
                    data.commodityCodeStatus = "invalid";
                    data.icdCharge = 0;
                    data.vatCharge = 0;
                    data.lines[itemIndex]["commodityCodeId"] = 0;
                });
        },
        updateRates(shippedOnBoardDate) {
            if (this.customsEntryForm.userDetails === null || this.customsEntryForm.userDetails === undefined) {
                return;
            }
            console.log(
                "Updating Rates. Using Target Currency Id: ",
                this.customsEntryForm.userDetails.mawi_business.country.currency.currency_code
            );
            const invoiceCurrency = this.invoiceDataArray.map((invoice) => invoice.currency);
            const chargeCurrencies = [
                this.customsEntryForm.freightCurrency,
                this.customsEntryForm.insuranceCurrency,
                this.customsEntryForm.otherChargesCurrency,
            ];
            const allCurrencies = [...invoiceCurrency, ...chargeCurrencies];
            const uniqueCurrencies = [...new Set(allCurrencies)];

            Utilities.getRatesOfExchange(
                uniqueCurrencies,
                shippedOnBoardDate,
                this.customsEntryForm.userDetails.mawi_business.country.main_currency_id
            ).then((rates) => {
                this.customsEntryForm.ratesOfExchange = rates;
                this.invoiceDataArray.forEach((invoice) => {
                    if (invoice.currency) {
                        console.log("Invoice Currency: ", invoice.currency);
                        const invoiceCurrency = rates.find((rate) => rate.base_currency_id === invoice.currency);
                        invoice.rate_of_exchange = invoiceCurrency ? invoiceCurrency.exchange_rate : 1.0;
                        invoice.rateOfExchangeId = invoiceCurrency ? invoiceCurrency.id : 0;
                    }
                });
            });
        },
        setDefaults() {
            const regimeData = this.customsEntryForm.regimeTypeOptions.find(
                (regime) => regime.regimeTypeCode === Configuration.defaultRegimeTypeCode
            );
            this.customsEntryForm.regimeType = regimeData.regimeTypeId;
            console.log("Regime Data: ", regimeData);
        },
        async getRegimeTypes() {
            return FastAPI.getRegimeTypes().then((data) => {
                console.log("Fast API Regime Types: ", data);
                this.customsEntryForm.regimeTypeOptions = data;
                const regimeData = data.find(
                    (regime) => regime.regimeTypeCode === Configuration.getConfigurations().defaultRegimeType
                );
                this.customsEntryForm.regimeType = regimeData.regimeTypeId;
            });
        },
        insertFullStops(numberString) {
            if (numberString.length !== 11) {
                console.log("The string must contain exactly 11 numbers.", numberString);
                return numberString;
            }

            return (
                numberString.slice(0, 4) +
                "." +
                numberString.slice(4, 6) +
                "." +
                numberString.slice(6, 8) +
                "." +
                numberString.slice(8)
            );
        },
        convertAWSData(invoices) {
            return invoices
                .map((invoiceArray) => {
                    return invoiceArray.map((invoice) => {
                        const summary = invoice.summary_fields;
                        const items = invoice.line_items.map((line) => {
                            // Initialize a temporary storage for the fields
                            const tempItem = {
                                product_code: "",
                                quantity: "",
                                unit_price: "",
                                description: "",
                                extension_price: "",
                                tariff_code: "",
                            };

                            // Loop through each object in the line array and assign values based on field_type
                            line.forEach((item) => {
                                switch (item.field_type) {
                                    case "PRODUCT_CODE":
                                        tempItem.product_code = item.cell_data.text;
                                        break;
                                    case "QUANTITY":
                                        tempItem.quantity = item.cell_data.text;
                                        break;
                                    case "UNIT_PRICE":
                                        tempItem.unit_price = item.cell_data.text;
                                        break;
                                    case "ITEM":
                                        tempItem.description = item.cell_data.text;
                                        break;
                                    case "PRICE":
                                        tempItem.extension_price = item.cell_data.text;
                                        break;
                                    case "recommended_commodity_code":
                                        console.log("THN Code: ", item.code);
                                        tempitem.tariff_code = this.insertFullStops(item.code.toString());
                                        break;
                                }
                            });
                            console.log("Item: ", line);
                            console.log("Temp Item: ", tempItem);
                            return tempItem;
                        });

                        return {
                            vendor_name: summary.VENDOR_NAME,
                            subtotal: summary.SUBTOTAL,
                            invoice_total: summary.TOTAL,
                            customer_name: summary.RECEIVER_NAME,
                            invoice_date: summary.INVOICE_RECEIPT_DATE,
                            invoice_number: summary.INVOICE_RECEIPT_ID,
                            lines: items,
                        };
                    });
                })
                .flat();
        },
        async addSupplier(supplierName){
            const newPartner = {
                name: supplierName,
            };
            try {
                const partnerResponse = await FastAPI.createPartner(newPartner);
                console.log("Partner Response: ", partnerResponse);
                const newSupplier = {
                    partner_id: partnerResponse.id,
                };
                console.log("New Supplier: ", newSupplier);
                const supplierResponse = await FastAPI.addSupplierPartner(newSupplier);
                console.log("Supplier Response: ", supplierResponse);
                return supplierResponse.id
            } catch (error) {
                console.error("Failed to add supplier:", error);
                SweetAlertConnector.showErrorAlert("Failed to add supplier");
                throw error;
            }
        },
        async setSupplierIds() {
            let promises = [];
            this.invoiceDataArray.forEach(async (invoice) => {
                console.log("Searching for Supplier: ", invoice.vendor_name);
                promises.push(
                    FastAPI.searchPartners(invoice.vendor_name)
                        .then(async (results) => {
                            console.log("Supplier ID: ", results);
                            
                            if (results.length > 0) {
                                const matchingPartner = results[0];
                                if(matchingPartner.supplier === null || matchingPartner.supplier === undefined){
                                    console.log("Found Partner but not a supplier. Adding new supplier");
                                    const supplierRequest = {
                                        partner_id: matchingPartner.id,
                                    };
                                    const supplierResponse = await FastAPI.addSupplierPartner(supplierRequest);
                                    console.log("New Supplier Response: ", supplierResponse);
                                    await this.$refs.documentTableView.getSuppliers();
                                    invoice.supplier_id = supplierResponse.id;
                                }
                                else{
                                    invoice.supplier_id = matchingPartner.supplier.id;
                                }
                                for (const result of results) {
                                    // Using 'for...of' for better control
                                    if (result.supplier != null && result.supplier != undefined) {
                                        invoice.supplier_id = result.supplier.id;
                                        break; // Exit the loop once the supplier is found and ID is set
                                    }
                                }
                            }
                            else {
                                console.log("Supplier not found. Adding new supplier");
                                // return this.addSupplier(invoice.vendor_name).then((supplierId) => {
                                //     await this.$refs.documentTableView.getSuppliers();
                                //     invoice.supplier_id = supplierId;
                                // });
                                const supplierId = await this.addSupplier(invoice.vendor_name);
                                await this.$refs.documentTableView.getSuppliers();
                                invoice.supplier_id = supplierId;
                            }

                        })
                        .catch((error) => {
                            console.error("Failed to get supplier ID:", error);
                        })
                );
            });
            return Promise.all(promises);
        },
        formatInvoiceData() {
            const allcpcCodes = this.customsEntryForm.regimeTypeOptions.flatMap((regime) => regime.cpcCodes);
            const defaultCPCId = allcpcCodes.find(
                (cpc) => cpc.code === Configuration.getConfigurations().defaultCPCCode
            ).cpcId;
            const defaultNPCCode = this.availableNPCCodes.find((npc) => npc.code === "000").npcId;
            this.renameInvoiceObjectKeys();
            this.invoiceDataArray.forEach((invoice) => {
                invoice.invoice_total = invoice.invoice_total
                    ? this.parseFloatFromString(invoice.invoice_total)
                    : invoice.invoice_exworks_amount
                    ? this.parseFloatFromString(invoice.invoice_exworks_amount)
                    : 0.0;
                invoice.inland = invoice.invoice_internal_freight_amount
                    ? this.parseFloatFromString(invoice.invoice_internal_freight_amount)
                    : 0.0;
                invoice.insurance = invoice.invoice_insurance_amount
                    ? this.parseFloatFromString(invoice.invoice_insurance_amount)
                    : 0.0;
                invoice.otherCharges = invoice.invoice_other_charges_amount
                    ? this.parseFloatFromString(invoice.invoice_other_charges_amount)
                    : 0.0;
                invoice.final_total = this.parseFloatFromString(invoice.final_total);
                invoice.invoice_number = invoice.invoice_id;
                invoice.invoice_date = invoice.invoice_date
                    ? Utilities.getDateStringInputField(Utilities.parseDate(invoice.invoice_date))
                    : null;
                invoice.currency = this.currencies.find((currency) => currency.code === "USD")?.id;
                if (invoice.lines !== undefined && invoice.lines !== null) {
                    invoice?.lines.forEach((item) => {
                        if (item.country_of_origin_id !== undefined && item.country_of_origin_id !== null) {
                            const selectedCountry = this.customsEntryForm.countryList.find(
                                (country) => country.code === item.country_of_origin_id
                            );
                            item.country_of_origin_id =
                                selectedCountry === undefined
                                    ? this.customsEntryForm.countryOfOrigin
                                    : selectedCountry.id;
                        } else item.country_of_origin_id = this.customsEntryForm.countryOfOrigin;
                        item.product_code = item.product_number;
                        item.quantity = this.parseFloatFromString(item.quantity);
                        item.unit_price = this.parseFloatFromString(item.unit_price);
                        item.extension_price = this.parseFloatFromString(item.amount).toFixed(2);
                        item.vatApplicable = true;
                        item.cpcCode = defaultCPCId;
                        item.npcCode = defaultNPCCode;
                        item.originalLine = structuredClone(item);
                        // item.vat_applicable = item.vat_applicable === 'Yes'
                    });
                }
                invoice.originalInvoice = structuredClone(invoice);
            });
        },
        renameInvoiceObjectKeys() {
            for (let i = 0; i < this.invoiceDataArray.length; i++) {
                let { invoice_items: lines, ...rest } = this.invoiceDataArray[i];
                this.invoiceDataArray[i] = { lines, ...rest };
            }
        },
        getTotalNumberOfLines() {
            let total = 0;
            for (let invoice of this.invoiceDataArray) {
                total += invoice.lines ? invoice.lines.length : 0;
            }
            this.totalNumberOfLines = total;
        },
        calculateTotalCargoValue() {
            let total = 0;
            for (let invoice of this.invoiceDataArray) {
                total += this.parseFloatFromString(invoice.invoice_total);
            }
            this.customsEntryForm.totalCargoValue = total;
        },
        validateIntegerInput(event) {
            // Get the entered text
            let newText = event.target.innerText.trim();

            // Check if the entered text is a valid integer
            if (/^\d+$/.test(newText)) {
                // Update the content if it's a valid integer
                this.editableContent = newText;
            } else {
                // Revert to the previous content if it's not a valid integer
                event.target.innerText = this.editableContent;
            }
            this.moveCursorToEnd(event.target);
        },
        updateEntryFields(fieldName, value) {
            this[fieldName] = value;
        },
        selectOption(option) {
            this.$refs.editableDiv.innerText = option;
            this.showModeofTransportDropdown = false;
        },
        verifyData(data) {
            if (data === undefined || data === null || data === "") {
                return false;
            }
            return true;
        },
        validateData() {
            console.log("Importer ID: ", this.verifyData(this.customsEntryForm.importerId));
            if (!this.verifyData(this.customsEntryForm.importerId)) {
                SweetAlertConnector.showErrorAlert("Please select an importer");
                return false;
            }
            if (!this.verifyData(this.customsEntryForm.exporterId)) {
                SweetAlertConnector.showErrorAlert("Please select an exporter");
                return false;
            }
            return true;
        },
        redirectToEntryDetails(entryId) {
            this.$router.push({ name: "EntryDetails", params: { entryId } });
        },
        moveCursorToEnd(element) {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(element);
            range.collapse(false); // Collapse range to end
            selection.removeAllRanges();
            selection.addRange(range);
        },
        validateInput() {
            this.isValidReference = this.referenceNumber.trim().length > 0;
        },
        handleFileUpload(event) {
            if (!this.isValidReference) return;
            this.selectedFiles = Array.from(event.target.files);
            this.createFileUrl();
        },
        handleWaybillUpload(event) {
            if (!this.isValidReference) return;
            this.waybillFiles = Array.from(event.target.files);
        },
        createFileUrl() {
            for (let file of this.selectedFiles) {
                if (file.type === "application/pdf") {
                    const url = URL.createObjectURL(file);
                    this.pdfFiles.push({
                        name: file.name,
                        url: url,
                    });
                } else {
                    alert(`File "${file.name}" is not a valid PDF file.`);
                }
            }
        },
        async openWaybill() {
            if (this.waybillFiles.length === 0) {
                SweetAlertConnector.showErrorAlert("No waybills Uploaded");
                return;
            }

            try {
                // Assuming only one file is selected for this function
                const file = this.waybillFiles[0];

                // Convert the file into an ArrayBuffer
                const arrayBuffer = await file.arrayBuffer();

                // Convert the ArrayBuffer into a Blob
                const blob = new Blob([arrayBuffer], { type: "application/pdf" });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);

                // Open a new window and display the PDF in an iframe
                const newWindow = window.open("", "_blank", "width=800,height=600");

                if (newWindow) {
                    newWindow.document.write(
                        `<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`
                    );
                    newWindow.document.title = "PDF Document";
                } else {
                    console.error("Failed to open new window.");
                }
            } catch (error) {
                console.error("Error:", error);
                SweetAlertConnector.showErrorAlert("Failed to open PDF!");
            }
        },
        async mergeAndOpenPDFs() {
            if (this.selectedFiles.length === 0) {
                SweetAlertConnector.showErrorAlert("Please upload at least one file.");
                return;
            }

            try {
                const pdfDocs = await Promise.all(
                    this.selectedFiles.map(async (file) => {
                        const arrayBuffer = await file.arrayBuffer();
                        return PDFDocument.load(arrayBuffer);
                    })
                );

                const mergedPdf = await PDFDocument.create();

                for (const pdfDoc of pdfDocs) {
                    const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                    copiedPages.forEach((page) => mergedPdf.addPage(page));
                }

                const mergedPdfBytes = await mergedPdf.save();
                const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                const newWindow = window.open("", "_blank", "width=800,height=600");

                if (newWindow) {
                    newWindow.document.write(
                        `<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`
                    );
                    newWindow.document.title = "Merged PDF";
                } else {
                    console.error("Failed to open new window.");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        },
        dragOver(event) {
            if (!this.isValidReference) return;
            this.isDragOver = true;
        },
        dragEnter(event) {
            if (!this.isValidReference) return;
            this.isDragOver = true;
        },
        dragLeave(event) {
            if (!this.isValidReference) return;
            this.isDragOver = false;
        },
        readWaybillModelClaude() {
            const formData = new FormData();
            this.waybillFiles.forEach((file) => {
                formData.append("waybill", file);
            });
            this.waybillProcessingStart = new Date();
            FastAPI.readWaybillLLM(formData)
                .then((response) => {
                    this.waybillDataExtractionCompleted = true;
                    this.waybillProcessingEnd = new Date();
                    this.dataExtractionLoadingMessage();
                    const waybillData = response[0];
                    console.log("Waybill Data", waybillData);
                    this.customsEntryForm.waybillNumber = waybillData.waybill_number;
                    this.customsEntryForm.numberOfPackages = this.parseFloatFromString(waybillData.number_of_packages);
                    // this.customsEntryForm.modeOfTransport = waybillData.mode_of_transport
                    const selectedModeOfTransport = Utilities.findClosestMatch(
                        waybillData.mode_of_transport,
                        this.customsEntryForm.modeOfTransportOOptions
                    );
                    this.customsEntryForm.modeOfTransport = selectedModeOfTransport.id;

                    const selectedFreightType = Utilities.findClosestMatch(
                        waybillData.freight_type,
                        this.customsEntryForm.freightTypeOptions
                    );
                    this.customsEntryForm.freightType = selectedFreightType.id;

                    // Find closest match for importer
                    const selectedImporter = Utilities.findClosestMatch(
                        waybillData.consignee_name,
                        this.customsEntryForm.importers
                    );
                    this.customsEntryForm.importerId = selectedImporter.id;

                    // Find closest match for exporter
                    const selectedExporter = Utilities.findClosestMatch(
                        waybillData.shipper_name,
                        this.customsEntryForm.exporters
                    );
                    this.customsEntryForm.exporterId = selectedExporter.id;

                    // Find closest match for vessel
                    if (waybillData.vessel_name != null && waybillData.vessel_name !== undefined) {
                        const selectedVessel = Utilities.findClosestMatch(
                            waybillData.vessel_name,
                            this.customsEntryForm.vessels
                        );
                        this.customsEntryForm.vesselId = selectedVessel.id;
                    }

                    // Find closest match for port of discharge
                    if (waybillData.port_of_discharge != null && waybillData.port_of_discharge != undefined) {
                        const selectedPort = Utilities.findClosestMatch(
                            waybillData.port_of_discharge,
                            this.customsEntryForm.ports ?? []
                        );
                        this.customsEntryForm.portOfDischargeId = selectedPort.id;
                    }

                    if (waybillData.port_of_loading != null && waybillData.port_of_loading != undefined) {
                        const selectedPortOfLoading = Utilities.findClosestMatch(
                            waybillData.port_of_loading,
                            this.customsEntryForm.ports
                        );
                        this.customsEntryForm.portOfLoadingId = selectedPortOfLoading.id;
                    }

                    const shippedOnBoardString = waybillData.shipment_date;
                    try {
                        const parsedDate = Utilities.parseDate(shippedOnBoardString);
                        this.customsEntryForm.waybillDate = Utilities.formatDateString(parsedDate);
                    } catch (error) {
                        console.error("Failed to parse date", error);
                    }

                    if (
                        waybillData.total_amount !== undefined &&
                        waybillData.total_amount !== null &&
                        waybillData.total_amount.trim().length > 0
                    ) {
                        const freightCharge = Utilities.parseFreightCharges(waybillData.total_amount);
                        this.customsEntryForm.freightAmount = freightCharge;
                    }

                    if (waybillData.gross_weight !== undefined && waybillData.gross_weight !== null) {
                        this.customsEntryForm.grossWeight = this.parseFloatFromString(waybillData.gross_weight);
                    }

                    if (waybillData.package_type !== undefined && waybillData.package_type !== null) {
                        const selectedPackageType = Utilities.findClosestMatch(
                            waybillData.package_type,
                            this.customsEntryForm.packageTypes,
                            "text"
                        );
                        this.customsEntryForm.packageType = selectedPackageType.id;
                    }

                    if (waybillData.country_of_origin !== undefined && waybillData.country_of_origin !== null && waybillData.country_of_origin.trim().length > 0) {
                        // this.customsEntryForm.countryOfOrigin = waybillData.country_of_origin
                        // const selectedCountryOfOrigin = Utilities.findClosestMatch(waybillData.country_of_origin, this.customsEntryForm.countryList, )
                        const selectedCountryOfOrigin = this.customsEntryForm.countryList.find(
                            (country) => country.code === waybillData.country_of_origin
                        );
                        this.customsEntryForm.countryOfOrigin = selectedCountryOfOrigin.id;
                    }
                })
                .catch((error) => {
                    console.error("Failed to read waybill model", error);
                    SweetAlertConnector.showErrorAlert("Failed to read waybill model. Please try again.");
                });
        },

        async uploadInvoicesAndWaybills(
            url = "https://gabrielle-flask.azurewebsites.net/read_vendor_invoice_optimized",
            modelUtilized = INVOICE_AI_MODELS.MICROSOFT_FORM_RECOGNIZER
        ) {
            if (!this.isValidReference || this.selectedFiles.length === 0) {
                SweetAlertConnector.showErrorAlert(
                    "Please enter a valid reference number and select files before uploading."
                );
                return;
            }
            SweetAlertConnector.showLoadingAlert("Reading your invoice and Waybill");
            this.invoiceModelUtilized = modelUtilized;
            this.uploadFilesMicrosoft(url);
            if (this.waybillFiles.length > 0) {
                // this.readWaybillModel();
                this.readWaybillModelClaude();
            } else {
                console.log("No Waybill Files");
                this.waybillDataExtractionCompleted = true;
            }
        },
        transformArray(data) {
            // Check if the data is an array of arrays
            if (Array.isArray(data) && Array.isArray(data[0])) {
                // Flatten the array of arrays
                return data.flat();
            }
            // Return the data as is if it's already in the desired format
            return data;
        },
        async compressFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const fileAsArray = new Uint8Array(e.target.result);
                    const compressedFileArray = pako.deflate(fileAsArray);
                    // const compressedFile = new Blob([compressedFileArray], { type: file.type });
                    const compressedFile = compressedFileArray.buffer;
                    const dataToUpload = new Blob([compressedFile], { type: file.type });
                    const fileToUpload = new Blob([dataToUpload], { type: file.type });

                    // Log the original and compressed file sizes
                    console.log("Original file size:", file.size, "bytes");
                    console.log("Compressed file size:", fileToUpload.size, "bytes");

                    // Calculate and log the percentage decrease in file size
                    const percentageDecrease = ((file.size - fileToUpload.size) / file.size) * 100;
                    console.log("Percentage decrease in file size:", percentageDecrease.toFixed(2), "%");

                    // Download the compressed file
                    const downloadLink = document.createElement("a");
                    downloadLink.href = URL.createObjectURL(fileToUpload);
                    downloadLink.download = `compressed_${file.name}`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);

                    resolve(fileToUpload);
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            });
        },
        // Calling function in your uploadFilesMicrosoft method

        async uploadFilesMicrosoft(url) {
            console.log("Uploading Files", url);
            const formData = new FormData();
            formData.append("reference", this.referenceNumber);
            this.selectedFiles.forEach((file, index) => {
                // formData.append("files", file);
                const newFileName = `${this.referenceNumber}_commercial_invoice_${index + 1}.${file.name
                    .split(".")
                    .pop()}`;
                const renamedFile = new File([file], newFileName, { type: file.type });
                formData.append("commercial_invoices", renamedFile);
            });
            // SweetAlertConnector.showLoadingAlert("Reading your invoice...");
            this.invoicingProcessingStart = new Date();
            console.log("Time User clicked Upload (UTC): ", this.invoicingProcessingStart.toISOString());

            FastAPI.readInvoiceLLM(formData)
                .then(async (data) => {
                    this.invoicingProcessingEnd = new Date();
                    console.log("Time Invoice Data Received (UTC): ", this.invoicingProcessingEnd.toISOString());
                    this.invoicesDataExtractionCompleted = true;
                    if (Utilities.discountCheck(data)) {
                        console.log("Discount Detected. Please review the invoice data.");
                        SweetAlertConnector.closeCurrentAlert();
                        const discountResult = await SweetAlertConnector.discountAlert();
                    }
                    this.dataExtractionLoadingMessage();

                    this.invoiceDataArray = this.transformArray(data);
                    this.invoiceDataArray = this.invoiceDataArray.filter(
                        (invoice) => invoice.invoice_items && Array.isArray(invoice.invoice_items)
                    );
                    this.formatInvoiceData();
                    console.log("Invoice Data Read", this.invoiceDataArray);
                    if (
                        this.customsEntryForm.waybillDate != null &&
                        this.customsEntryForm.waybillDate != undefined &&
                        this.customsEntryForm.waybillDate != ""
                    ) {
                        console.log("Updating rates for invoices");
                        this.updateRates(this.customsEntryForm.waybillDate);
                    }
                    await this.setSupplierIds();
                    this.originalInvoiceDataArray = structuredClone(this.invoiceDataArray);
                    // SweetAlertConnector.closeCurrentAlert();
                    // SweetAlertConnector.showSuccessAlert("Invoice data read successfully.");
                    this.calculateTotalCargoValue();
                    this.getTotalNumberOfLines();
                    if (this.invoicesDataExtractionCompleted && this.waybillDataExtractionCompleted) {
                        SweetAlertConnector.asyncLoadingAlert("Classifying Invoice Items...");
                    }
                    this.classificationProcessingStart = new Date();
                    this.classifyInvoiceItems().then((commodityCodes) => {
                        this.classificationCompleted = true;
                        SweetAlertConnector.closeCurrentAlert();
                        this.classificationProcessingEnd = new Date();
                        console.log(
                            "Time Full Response Received (UTC): ",
                            this.classificationProcessingEnd.toISOString()
                        );
                        SweetAlertConnector.showSuccessAlert("Invoice data read and classified.");
                        this.calculateTotalCargoValue();
                        this.getTotalNumberOfLines();
                    });
                })
                .catch((error) => {
                    SweetAlertConnector.showErrorAlert("Failed to read invoice data. Please try again.");
                    console.error("Error:", error);
                    this.invoiceDataArray = [];
                });
        },
        removeUnsafeCharacters(str) {
            // Define a regular expression for characters that are considered safe in URLs
            const safeChars = /^[a-zA-Z0-9-_.~ ]*$/;

            // Filter the string to keep only safe characters
            return Array.from(str)
                .filter((char) => safeChars.test(char))
                .join("");
        },
        async classifyInvoiceItems() {
            console.log("Classifying Invoice Items");
            console.log("Invoice Data Array: ", this.invoiceDataArray);

            const descriptions = this.invoiceDataArray.flatMap((invoice) => {
                // Map the invoice lines to the desired format.
                let formattedItems = invoice.lines
                    .filter((item) => !item.tariff_code)
                    .map((item) => ({
                        description: `${item.description} ${item.classification_description}` || item.description || "",
                        commodity_code: item.commodity_code || "",
                        product_number: item.product_number || item.product_code || "",
                    }));
                const chunkSize = 20;
                const chunks = [];
                for (let i = 0; i < formattedItems.length; i += chunkSize) {
                    chunks.push(formattedItems.slice(i, i + chunkSize)); // Removed extra brackets here.
                }

                return chunks;
            });
            
            if (descriptions.length === 0) {
                console.log("No descriptions to classify.");
                return [];
            }
            
            console.log("Descriptions: ", descriptions);
            
            // Create an array of promises for all the chunks
            const promises = descriptions.map((chunk) => {
                console.log("Chunk: ", chunk);
                return FastAPI.getCommodityCode([chunk])
                    .then((result) => {
                        this.processCommodityCodes(result[0]); // Process the first result or handle as needed.
                        return result;
                    })
                    .catch((error) => {
                        console.error("Failed to classify items:", error);
                        return []; // Return an empty array in case of failure to avoid breaking the flow.
                    });
            });

            // Wait for all the promises to resolve
            const results = await Promise.all(promises);

            // Flatten the results array since each chunk returns an array of commodity codes
            const commodityCodes = results.flat();
            
            return commodityCodes;
        },


        processCommodityCodes(commodityCodes) {
            console.log("Commodity Codes Processing: ", commodityCodes);
            commodityCodes.forEach((commodityCode) => {
                this.invoiceDataArray.forEach((invoice) => {
                    invoice.lines.forEach((item, itemIndex) => {
                        if (
                            commodityCode.description === `${item.description} ${item?.classification_description}` ||
                            commodityCode.description === `${item.description}`
                        ) {
                            if (
                                commodityCode.classification_model_suggested_code !== undefined &&
                                commodityCode.classification_model_suggested_code !== null
                            ) {
                                item.tariff_code = this.insertFullStops(
                                    commodityCode.classification_model_suggested_code.toString()
                                );
                                this.updateTariffCode(invoice, itemIndex, "tariff_code", item.tariff_code, commodityCode.confidence_score);
                            }
                        }
                    });
                });
            });
        },
        updateItem(data, index, field, value) {
            data.lines[index][field] = value;
        },
        parseFloatFromString(inputString) {
            if (!inputString || inputString === undefined || inputString === null) {
                return 0;
            }

            if (typeof inputString === "number") {
                return inputString;
            }
            // Remove non-numeric characters except for period (.)
            let numericString = inputString.replace(/[^0-9.]/g, "");

            // Parse the numeric string as a float
            let floatValue = parseFloat(numericString);

            return floatValue;
        },
    },
    beforeUnmount() {
        // Clean up all object URLs when the component is destroyed
        this.pdfFiles.forEach((pdf) => URL.revokeObjectURL(pdf.url));
    },
};

function verifyInvoiceData() {
    this.displayDocumentVerificationView = !this.displayDocumentVerificationView;
}
</script>

<style>
/* Add your styles here */
@import "vue3-select/dist/vue3-select.css";
.badge-container {
    margin: 10px;
}
button {
    margin: 5px;
    cursor: pointer;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    text-align: center;
}
.upload-button {
    margin-left: 5px;
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 5px;
    background: #fafafa;
    color: #bdbdbd;
    margin: 10px;
    font-size: 24px;
}

.dropzone.dragover {
    border-color: #3f51b5;
    background-color: #eeeeee;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}
.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
}

.dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dropdown ul li {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown ul li:hover {
    background-color: #f0f0f0;
}

.form-row {
    text-align: left;
    padding: 20px;
}
.page-header-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 0.5rem;
}
/* Container styling */
.file-list-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 99%;
    margin-inline: auto;
}

/* Row styling */
.file-list-container .row {
    display: flex;
    gap: 20px;
}

/* Column styling */
.file-list-container .col-md-6 {
    flex: 1;
    padding: 10px;
    background-color: #f7faff;
    border-radius: 10px;
}

/* List styling */
.file-list-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.file-list-container li {
    background: #e0f2ff;
    margin: 5px 0;
    padding: 8px 12px;
    border-radius: 5px;
    color: #2c3e50;
    box-shadow: 0 0px 4px 5px rgb(114 110 110 / 9%);
}

/* Header styling */
.file-list-container h3,
.file-list-container h4 {
    text-align: center;
    color: #3498db;
    margin-bottom: 6px;
    font-size: 20px;
}
/* Header styling */
.file-list-container h3 {
    font-size: 25px;
}
.customs-entry-form {
    width: 99%;
    margin-inline: auto;
    box-shadow: 0 0px 4px 5px rgb(114 110 110 / 9%);
    border-radius: 5px;
}
</style>
